import React from 'react';
import './artcarousel.scss';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../config/config';
import { AiFillCaretRight, AiFillCaretLeft } from 'react-icons/ai';
import { rkop, kop, kops, price, priceOld } from '../helpers/num';


const ArtCarousel = (props) => {

	return props.items.length > 0 ? (
		<div className="art-carousel">
			{props.title &&
				<div className="art-carousel-title">
					{props.title}
				</div>
			}
			<div className="art-carousel-container">
				<div className="carousel-left" onClick={() => props.setStart(-1)}><AiFillCaretLeft /></div>
				<div className="art-carousel-items">
					{props.items.map((x, i) => (
						<Link to={x.link || ''} key={x.id}>
							<div className="art-carousel-item" style={{ backgroundImage: `url(${BASE_URL}/images/article/${x.img})` }}>
								<div className="art-carousel-item-desc">
									<div className="art-carousel-item-desc-name">{x.name}</div>
									{/* <div className="art-carousel-item-desc-price">{rkop(x.price)}</div> */}
								</div>
								<div className="art-carousel-item-price">
									<div className="art-carousel-item-price-r">
										{Math.floor(price(x))}
									</div>
									<div className="art-carousel-item-price-k">
										{kops(price(x))}
									</div>
									{x.pricep > 0 &&
										<div className="art-carousel-item-pricep">
											<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
												<line x1="0" y1="100" x2="100" y2="00" stroke="black" />
											</svg>
											<div className="art-carousel-item-pricep-r">
												{Math.floor(priceOld(x))}
											</div>
											<div className="art-carousel-item-pricep-k">
												{kops(priceOld(x))}
											</div>
										</div>
									}
								</div>
							</div>
						</Link>
					))}
				</div>
				<div className="carousel-right" onClick={() => props.setStart(1)}><AiFillCaretRight /></div>
			</div>
		</div>
	) : (<div className="art-carousel"></div>);
}

export default ArtCarousel;