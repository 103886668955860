import React, {useState, useEffect} from "react";
import Main from "./main";
import Breadcrumb from "../components/breadcrumb";
import Construction from '../components/construction';
import ArtCarousel from '../components/artcarousel';
import { connect } from 'react-redux';
import { choose, basketAdd, favToggle, basketDel } from "../reducers/reducer";
import { BASE_URL } from '../config/config';
import Select from 'react-select';
import './store.scss';
import Carousel from "./carousel";
import Banner from "../components/banners/banner";
import Option from "../components/option";
import { FaSearch, FaPlusCircle, FaMinusCircle } from 'react-icons/fa';
import { render } from "@testing-library/react";
import {ReactComponent as Favourites} from '../assets/images/favorites.svg';
import {ReactComponent as Basket} from '../assets/images/basket.svg';
import { Link, useHistory } from 'react-router-dom';
import { kop, carItems, price, priceOld, kops, qtyPres } from '../helpers/num';
import Pages from '../components/pages';
import ChooseStore from "./chooseStore";
import moment from "moment";

const alco = '5fc74851daa7a503e03ea0ca';
const tobacco = '5fc74851daa7a503e03ea0d0';
const KG = 'кг';

export function useDebounce(value, delay) {
	const [debouncedValue, setDebouncedValue] = useState(value);
  
	useEffect(
	  () => {
		const handler = setTimeout(() => {
		  setDebouncedValue(value);
		}, delay);
  
		return () => {
		  clearTimeout(handler);
		};
	  },
	  [value]
	);
  
	return debouncedValue;
}

const Store = ({ store, choose, location, fav, favToggle, basket, bAdd, bDel, handlePlus, handleMinus, theme }) => {
	const mobile = window.screen.width <= 400;

	const storeId = store ? store.id : sessionStorage.getItem('store');

	const history = useHistory();

	const [promo, setPromo] = useState(false);

	// const [cities, setCities] = useState([]);
	// const [addresses, setAddresses] = useState([]);

	// const [city, setCity] = useState();
	// const [address, setAddress] = useState();
	
	const [categories, setCategories] = useState([]);
	const [category, setCategory] = useState(null);
	const [catPath, setCatPath] = useState([]);
	const [offsetCats, setOffsetCats] = useState(1);
	const catsL = 16;
	
	const [articles, setArticles] = useState([]);
	const [article, setArticle] = useState(undefined);
	const [offsetArticles, setOffsetArticles] = useState(1);
	const [articlesL, setArticlesL] = useState(16);

	const [search, setSearch] = useState('');
	const [searchArticle, setSearchArticle] = useState('');
	
	const [basketContinue, setBasketContinue] = useState(false);
	
	const [cnt, setCnt] = useState(1);
	const [startSimilar, setStartSimilar] = useState(0);


	const [media, setMedia] = useState(undefined);
	const dsearch = useDebounce(search, 1000);
	const [qtyInput, setQtyInput] = useState(undefined);

	const sess18 = sessionStorage.getItem('more18');
	const [more18, setMore18] = useState(sess18 === 'true' ? true : (sess18 === 'false' ? false : undefined));

	// const getStores = () => {
	// 	fetch(`${BASE_URL}/store`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
	// 	.then(res => res.json())
	// 	.then(res => {
	// 		setCities(res.filter(x => x.active).map(x => ({label: x.name, value: x.id }) ));
	// 		setAddresses(res.filter(x => x.active).map(x => ({label: x.address, value: x }) ));
	// 	});
	// }

	const getCategories = () => {
		fetch(`${BASE_URL}/category`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
		.then(res => res.json())
		.then(res => {
			setCategories(res);
		});
	}

	const getArticle = async (id) => {
		const res = await fetch(`${BASE_URL}/article/info/${id}?store=${storeId}`, { method: 'GET', headers: { 'Content-Type': 'application/json' } });
		const ob = await res.json();
		return ob;
	}
	
	const updPath = async () => {
		const cats = location.pathname.split('/');
		let current = null;
		let cp = [];
		let clink = '/store';
		for(let i = 2; i < cats.length; i++) {
			clink += '/' + cats[i];
			current = categories.find(x => x.id === cats[i]);
			if (current) cp.push({...current, link: clink });
		}
		setCategory(current);
		setCatPath(cp);
		const params = new URLSearchParams(location.search);
		const art = params.get('article');
		setPromo(!!params.get('promo'));
		if (art) {
			const artInfo = await getArticle(art);
			setArticle(artInfo);
		} else setArticle(undefined);
	}

	useEffect(() => {
		// getStores();
		getCategories();
		fetch(`${BASE_URL}/banner/show`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => res.json())
			.then(res => {
				setMedia(res || []);
			})
		const params = new URLSearchParams(location.search);
		if (params.get('promo')) setPromo(true);
		if (store && !params.get(store)) history.push(location.pathname + `?store=${storeId}` + (params.get('promo') ? '&promo=1' : ''))
	}, [])
	useEffect(() => {
		updPath();
	}, [location, categories])

	useEffect(() => {
		setOffsetCats(1);
	}, [catPath]);

	
	useEffect(() => {
		if (category && [alco, tobacco].includes(category.id) && more18 === false) { history.push('/store'); setCategory(null); updPath(); }
		if ((category || dsearch || promo) && store) {
			fetch(`${BASE_URL}/article${category ? '/category/' + category.id : '/category'}?store=${store ? storeId : ''}${dsearch ? '&search=' + dsearch : ''}${promo ? '&promo=1' : ''}`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => res.json())
			.then(res => {
				setArticles(res);
			});
		} else setArticles([]);

	}, [category, dsearch, store, promo, more18])
	
	const renderCarousel = () => {
		let res;
		if (media) {
			if (media.length === 0) res = (<Carousel banners={[<Banner image="image11" />, <Banner image="image10" />, <Banner image="image9" />, <Banner image="image4" />, <Banner image="image5" />, <Banner image="image7" />]} />);
			else res = (<Carousel banners={media.filter(b => !!b && !!b.path).map(b => (<Banner url={b.path} />))} />);
		} else res = (<Carousel banners={[]} />);
		return res;
	}

	// const renderChooseStore = () => {
	// 	const enabled = city && address;
	// 	return (
	// 		<>
	// 			<div className="store-container">
	// 				<div className="store-title">МИ ПАКУЄМО - ВИ КУПУЄТЕ</div>
	// 				<div className="store-container-wrapper">
	// 					<div className="store-select-label">Місто:</div>
	// 					<Select placeholder="" options={cities} value={city} onChange={(v) => setCity(v)} className={'store-select' + (theme === 'white' ? ' store-select-shadow' : '')} />
	// 					<div className="store-select-label">Адреса магазина:</div>
	// 					<Select placeholder="" options={addresses.filter(x => city && x.value.id === city.value)} value={addresses.find(v => store && v.value.id === storeId)} onChange={(v) => setAddress(v.value)} className={'store-select' + (theme === 'white' ? ' store-select-shadow' : '')} />
	// 				</div>
	// 				<div className={`store-button ${enabled ? '' : 'disabled'}`} onClick={enabled ? () => {choose(address); history.push(location.pathname + '?store=' + address.id + (promo ? '&promo=1' : ''))} : () => {}}>Далі</div>
	// 			</div>
	// 		</>
	// 	);
	// }

	const renderSearch = (search, setSearch) => {
		return (
			<div className="search-container">
				<FaSearch className="search-icon" />
				<input className="search" value={search} onChange={(e) => setSearch(e.target.value)} />
			</div>
		);
	}

	const handleFav = (e, article) => {
		e.stopPropagation();
		favToggle(article);
	}

	const handleBasket = (e, article, add) => {
		e.stopPropagation();
		if (add) bAdd(article); else bDel(article.id);
	}

	const renderArticle = (article) => {
		const lnk = (article) ? (catPath.length > 0 ? catPath[catPath.length - 1].link : '') + '?article=' + article.id + `&store=${storeId}` + (promo ? '&promo=1' : '') : '';
		const ba = article ? basket.find(x => x.article.id === article.id) || { qty: 1 } : 1;
		const inBasket = basket.find(x => x.article.id === article.id && x.qty > 0);

		// console.log(price(article), kops(price(article)));
		return !article ? (<></>) : (
			<div className="article-container">
				<div key={article.id} className="article" onClick={() => { /*setCnt(ba.qty);*/ history.push(lnk); window.scrollTo(0, 0); }}>
					<div className="article-title">{article.name}</div>
					{(article.pricep > 0 && !promo) &&
						<div className="article-promo">АКЦІЯ</div>
					}
					{(article.pricep > 0 && article.promostart && article.promofinish) &&
						<div className="article-promo-period">{moment(article.promostart).format('DD.MM')} - {moment(article.promofinish).format('DD.MM')}</div>
					}
					{promo &&
						<div className="article-title-price">
							<div className="article-price-1">
								{Math.floor(price(article))}
							</div>
							<div className="article-price-2">
								{kops(price(article))}
							</div>
						</div>
					}
					<div className="article-image" style={{ backgroundImage: `url(${BASE_URL}/images/article/${article.img})` }} />
					<div className="article-desc">
						<div className="article-name-unit">
							<div className="article-name">
								{article.name}, 1 {article.unit}
							</div>
						</div>
					</div>
					<div className="article-details">
						<div className="article-text">
							<div className="article-name-unit">
								<div className="article-name">
									{article.name}
								</div>
								<div className="article-unit">
									1 {article.unit}
								</div>
							</div>

						</div>
						<div className="article-price-btns">
							{!promo && 
								<div className="article-price">
									<div className="article-price-1">
										{Math.floor(price(article))}
									</div>
									<div className="article-price-2">
										{kops(price(article))}
									</div>
								</div>
							}
							{(article.pricep > 0 && promo) &&
								<>
									<div></div>
									<div className="article-price-old">
										<div className="article-price-1">
											{Math.floor(article.price)}
										</div>
										<div className="article-price-2">
											{kops(article.price)}
										</div>
										<svg width="100%" height="100%" viewBox="0 0 30 12" fill="none" xmlns="http://www.w3.org/2000/svg">
											<line x1="0.822673" y1="11.5325" x2="29.8227" y2="0.532502" stroke="#FF0000"/>
										</svg>
									</div>
								</>
							}
							<div className="article-price-btns-btns">
								<div className="article-price-btns-btns-fav">
									<Favourites className={'favourites' + (fav.find(x => x.id === article.id) ? ' favourites-chosen': '')} onClick={(e) => handleFav(e, article)} />
								</div>
								<div className="article-price-btns-btns-basket">
									<Basket className={'basket' + (inBasket ? ' basket-chosen': '')} onClick={(e) => handleBasket(e, article, !inBasket)} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	const renderButton = (label, click) => {
		return (
			<div className="art-btn" onClick={click}>
				{label}
			</div>
		)
	}

	const render18 = () => {
		return (
			<>
				{(more18 === undefined) &&
					<div className="more18">
						<div className="more18-btns">
							{renderButton('Мені немає 18 років', () => { sessionStorage.setItem('more18', false); setMore18(false); } )}
							{renderButton('Мені вже є 18 років', () => { sessionStorage.setItem('more18', true); setMore18(true); } )}
						</div>
						<div className="more18-text">
						*Алкоголь може продаватися лише особам, 
						що досягли 18 років. Тому при отриманні 
						замовлення вам може знадобитися паспорт 
						для підтврдження свого віку
						</div>
					</div>
				}
			</>
		);
	}

	const renderArticlePage = (article) => {
		const ba = basket.find(x => x.article.id === article.id) || { qty: 0 };
		const isWeight = article.unit === KG;
		const addq = isWeight ? 0.1 : 1;
		return (
			<div className="art-page-container">
				<div className={'art-page ' + (basketContinue ? 'art-page-faded' : '')}>
					<div className='art-page-desc'>
						<div className="art-name">
							{article.name}
						</div>
						{/* <div className="art-qty">
							<div>
								Количество: 
							</div>
							<div>
								<FaMinusCircle onClick={() => setCnt(Math.max(0, cnt - 1))} />
								&nbsp;{cnt}
								<FaPlusCircle onClick={() => setCnt(cnt + 1)} />
							</div>
						</div> */}
						<div className="article-price">
							<div className="article-price-1">
								{Math.floor((ba.qty || 1) * price(article))}
							</div>
							<div className="article-price-2">
								{kops((ba.qty || 1) * price(article))}
							</div>
						</div>
						{/* {cnt === 0 && renderButton('ДОДАТИ В КОШИК', () => { handlePlus(article, cnt - ba.qty) ;setBasketContinue(true)} )} */}
						<div className="art-page-btnbasket">
							{ba.qty === 0 && renderButton('ДОДАТИ В КОШИК', () => { handlePlus(article, addq) } )}
							{ba.qty > 0 &&
								<div className="artc-qty">
									<div className="artc-qty-left" onClick={() => handleMinus(article, addq)}><div className="arc-qty-left-v">-</div></div>
									<div className="artc-qty-qty">
										<input className="artc-qty-qty-v" value={qtyInput || qtyPres(ba.qty, isWeight)} onChange={(v) => setQtyInput(v.target.value)} onBlur={(v) => { if (Number(v.target.value) !== NaN && v.target.value !== 0) {handlePlus(article, Number(v.target.value) - ba.qty); setQtyInput(undefined);} } } />
										{/* <div className="arc-qty-qty-v">{ba.qty}</div> */}
									</div>
									<div className="artc-qty-right" onClick={() => handlePlus(article, addq)}><div className="arc-qty-right-v">+</div></div>
								</div>
							}
							<div className="art-page-basket">
								<Link to="/basket">
									ПЕРЕЙТИ ДО КОШИКУ
								</Link>
							</div>
						</div>
						{/* <div className="art-add" onClick={() => setBasketContinue(true)}>
							ДОДАТИ В КОШИК
						</div> */}
					</div>
					<div className="art-page-img" style={{ backgroundImage: `url(${BASE_URL}/images/article/${article.img})` }}>
					</div>
				</div>
				<div className="art-page-carousel">
					<ArtCarousel 
						title="Також обирають"
						items={carItems(filteredArticles, startSimilar, 4).map((x) => ({ ...x, link: `?store=${store ? storeId : ''}&article=${x.id}`  }) )}
						setStart={(ch) => setStartSimilar(filteredArticles.length === 0 ? 0 : ((startSimilar + ch < 0) ? filteredArticles.length - 1 : ((startSimilar + ch) % filteredArticles.length))) }
					/>
				</div>
				{basketContinue && 
					<div className="basket-continue">
						{renderButton('ПЕРЕЙТИ ДО КОШИКУ', () => { history.push('/basket'); setBasketContinue(false)} )}
						{renderButton('ПРОДОВЖИТИ ПОКУПКУ', () => { history.push(catPath[catPath.length - 1].link); setBasketContinue(false)} )}
					</div>
				}
			</div>
		);
	}

	let filteredCats = categories.filter(x => x.parent === (category ? category.id : null));
	// if (search && !category) filteredCats = filteredCats.filter(x => x.name.toLowerCase().includes(search.toLowerCase()))
	// let filteredArticles = articles;//.filter(x => x.assortment && x.price && x.count && x.active);
	let filteredArticles = articles.filter(x => x.price && (x.count && x.count > 0) && x.active);
	const catsToShow = filteredCats.slice((offsetCats - 1) * catsL, offsetCats * catsL);
	const articlesToShow = filteredArticles.slice((offsetArticles - 1) * articlesL, offsetArticles * articlesL);
	const promocats = categories.filter(x => x.parent === null).map(x => ({ label: x.name.toLowerCase(), value: x.id }));
	const cat18 = (category && [alco, tobacco].includes(category.id));

	const customStyles = {
        control: base => ({
            ...base,
            minHeight: 20,
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 4,
			height: 14,
			width: 14,
        }),
        clearIndicator: base => ({
            ...base,
            padding: 4
        }),
        multiValue: base => ({
            ...base,
            // backgroundColor: variables.colorPrimaryLighter
        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 6px',
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: 0,
        })
	};

	// console.log('CATEGORY ', category && category.id);
	return true ? (
		<>
			<Main store blur={cat18 && !more18}>
				<Breadcrumb items={[{label: 'Головна', link: ''}, { label: 'Online магазин', link: '/store' + (store ? '?store=' + (store ? storeId : '') : '')}]
					.concat(promo ? [{ label: 'Акції', link: '/store' + (store ? '?store=' + (store ? storeId : '') : '') + '&promo=1'}] : [])
					.concat(catPath.map(x => ({ label: x.name, link: x.link + `?store=${store ? storeId : ''}` + (promo ? '&promo=1' : '') })) )} />
				{/* <Breadcrumb items={[{label: 'Головна', link: ''}, { label: 'Online магазин', link: 'store'}] } /> */}
				{((!store || !article) && !promo) && renderCarousel()}
				{promo && 
					<div className="promoart">
						<div className="promoart-banner">
							<div className="promoart-banner-category">
								<div className="promoart-banner-category-label">КАТЕГОРІЇ ТОВАРІВ</div>
								<Select classNamePrefix="react-select" styles={mobile ? customStyles : undefined} value={promocats.find(x => category && x.value === category.id)} className="promoart-category" onChange={(v) => history.push('/store/' + v.value + (store ? '?store=' + (store ? storeId : '') : '') + '&promo=1') } options={promocats} />
							</div>
						</div>
					</div>
				}
				{!store && <ChooseStore />}
				{/* {(store && !category) && renderSearch(search, setSearch)} */}
				{(store && article === undefined && !promo) && renderSearch(search, setSearch)}

				{(store && !article && !dsearch && !promo) &&
					<>
						<div className="store-categories">
							{catsToShow.map(x => (
								<Option type={4} key={x.id} label={x.name} imagePath={'category/' + x.img} link={'/store/' + catPath.map(x => x.id).join('/') + '/' + x.id + `?store=${storeId}` + (promo ? '&promo=1' : '')} key={x.id} mayResize color="greenw" onClick={() => {window.scrollTo(0, 0); setOffsetArticles(1);}} />
							))}
						</div>
						<Pages num={offsetCats} len={Math.ceil(filteredCats.length / catsL)} setNum={setOffsetCats} />
					</>
				}
				{(store && article === undefined) &&
					<>
						{/* {category && renderSearch(searchArticle, setSearchArticle)} */}
						<div className="articles">
							{articlesToShow.map(r => renderArticle(r) )}
						</div>
						{articlesToShow.length > 0 &&
							<div className="articles-more" onClick={() => { setArticlesL(articlesL + 16) }}>ПОКАЗАТИ ЩЕ</div>
						}
						<Pages num={offsetArticles} len={Math.ceil(filteredArticles.length / articlesL)} setNum={setOffsetArticles} cb={() => { setArticlesL(16); window.scroll(0, 0); } } />
					</>
				}
				{(store && article !== undefined) &&
					<>
						{renderArticlePage(article)}
					</>
				}
			</Main>
			{(cat18) && render18()}
		</>
	) : (
		<Main>
			<Breadcrumb items={[{label: 'Головна', link: ''}, { label: 'Online магазин', link: 'store'}]} />
			<Construction />
		</Main>

	);
};

const mapStateToProps = (state) => ({
	store: state.store,
	fav: state.fav,
	basket: state.basket,
	theme: state.theme
});

const mapDispatchToProps = (dispatch) => ({
	choose: (store) => dispatch(choose(store)),
	bAdd: (article) => dispatch(basketAdd({ article: article, qty: 1 })),
	bDel: (articleId) => dispatch(basketDel(articleId)),
	handleMinus: (article, qty) => dispatch(basketAdd({ article: article, qty: -qty })),
	handlePlus: (article, qty) => dispatch(basketAdd({ article: article, qty: qty })),
	favToggle: (article) => dispatch(favToggle(article))
});

export default connect(mapStateToProps, mapDispatchToProps)(Store);
