import React, { useEffect, useState } from "react";
import Main from "./main";
import Breadcrumb from "../components/breadcrumb";
import Construction from '../components/construction';
import './cafe.scss';
import { BASE_URL } from '../config/config';
import Banner from "../components/banners/banner";
import Option from "../components/option";


import Carousel from "./carousel";

const Cafe = (props) => {
	const [media, setMedia] = useState(undefined);
	const [links, setLinks] = useState([]);
	const [active, setActive] = useState(undefined);

	useEffect(() => {
		fetch(`${BASE_URL}/banner/show`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => res.json())
			.then(res => {
				setMedia(res || []);
			})
		fetch(`${BASE_URL}/cafe/show`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => res.json())
			.then(res => {
				setLinks(res || []);
			})
	}, [])
	
	const renderCarousel = () => {
		let res;
		if (media) {
			if (media.length === 0) res = (<Carousel banners={[<Banner image="image11" />, <Banner image="image10" />, <Banner image="image9" />, <Banner image="image4" />, <Banner image="image5" />, <Banner image="image7" />]} />);
			else res = (<Carousel banners={media.filter(b => !!b && !!b.path).map(b => (<Banner url={b.path} />))} />);
		} else res = (<Carousel banners={[]} />);
		return res;
	}

	return (
		<Main>
			<Breadcrumb items={[{label: 'Головна', link: ''}, { label: 'Кафе', link: 'cafe', callback: () => { setActive(undefined); }}].concat(active ? [{ label: active.desc, link: 'cafe' }] : [])} />
			{/* {renderCarousel()} */}

			<div className="cafes">
				{(links.length > 0 && !active) &&
					links.map(l => (
						<Option mayResize label={l.desc} imagePath={l.imgPath} color="greenwhite" onClick={l.imgPathDet ? () => setActive(l) : () => {} } />
					))
				}
				{(links.length === 0 && !active) &&
					<>
						<Option mayResize label="Піца" image="cafe1" link="" color="greenwhite" />
						<Option mayResize label="Салати" image="cafe2" link="" color="greenwhite" />
						<Option mayResize label="Гарячі страви" image="cafe3" link="" color="greenwhite" />
						<Option mayResize label="Бургери" image="cafe4" link="" color="greenwhite" />
						<Option mayResize label="Солодощі" image="cafe5" link="" color="greenwhite" />
						<Option mayResize label="НАПОЇ" image="cafe6" link="" color="greenwhite" />

						{/* <div className="cafe cafe1"><div className="cafe-label">Піца</div></div>
						<div className="cafe cafe2"><div className="cafe-label">Салати</div></div>
						<div className="cafe cafe3"><div className="cafe-label">Гарячі страви</div></div>
						<div className="cafe cafe4"><div className="cafe-label">Бургери</div></div>
						<div className="cafe cafe5"><div className="cafe-label">Солодощі</div></div>
						<div className="cafe cafe6"><div className="cafe-label">НАПОЇ</div></div> */}
					</>
				}
			</div>
			{active &&
				<div className="cafe-det">
					<img src={`${BASE_URL}/images/${active.imgPathDet}`} alt="" />
				</div>
			}
		</Main>
	);
};

export default Cafe;
