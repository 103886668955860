import React, { useState } from "react";
import { connect } from 'react-redux';
import "./admin.scss";
import { signin } from "../reducers/reducer";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const Admin = ({ signin, isLoggedIn }) => {
	const [login, setLogin] = useState("");
	const [password, setPassword] = useState("");

	const field = (label, value, setValue, type) => {
		return (
			<div>
				<div className='label'>{label}</div>
				<input
					type={type}
					className='value'
					value={value}
					onChange={(e) => setValue(e.target.value)}
				/>
			</div>
		);
	};

	return (
		<div>
			{isLoggedIn &&
				<Redirect to="/admin/media" />
			}
			{field("Login", login, setLogin, 'text')}
			{field("Password", password, setPassword, 'password')}
			<button
				className='btn'
				style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '1rem'}}
				onClick={() => signin(login, password)}
			>
				Submit
			</button>
		</div>
	);
};

const mapStateToProps = (state) => ({
	isLoggedIn: state.isLoggedIn
});

const mapDispatchToProps = (dispatch) => ({
	signin: (login, password) => dispatch(signin({ login: login, password: password })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
