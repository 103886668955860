import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import AdminContainer from './admin_container';
import { BASE_URL } from '../../config/config';
import './admin.scss';

const VacancyStore = () => {

	const [stores, setStores] = useState([]);
	const [vacancies, setVacancies] = useState([]);
	const [store, setStore] = useState(undefined);
	const [vacancy, setVacancy] = useState(undefined);
	const [list, setList] = useState([]);

	const add = (store, vacancy) => {
		fetch(`${BASE_URL}/store/vacancy`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ vacancy: vacancy.value, store: store.value }) })
		.then(res => getAll())
	}

	const del = (store, id) => {
		fetch(`${BASE_URL}/store/vacancy`, { method: 'DELETE', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ store: store.value, id: id }) })
		.then(res => getAll())
	}

	const getAll = () => {
		if (store) {
			fetch(`${BASE_URL}/store/vacancy?id=${store.value}`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => res.json())
			.then(res => setList(res));
		} else setList([]);
	}

	const changePos = (store, id1, id2) => {
		fetch(`${BASE_URL}/store/vacancyOrder`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ store: store.value, id1: id1, id2: id2 }) })
		.then(res => getAll())
	}

	const setActive = (store, id, value) => {
		fetch(`${BASE_URL}/store/vacancyActive`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ store: store.value, id: id, value: value }) })
		.then(res => getAll())
	}

	const getStores = () => {
		fetch(`${BASE_URL}/store`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
		.then(res => res.json())
		.then(res => setStores(res.map(x => ({ label: x.name, value: x.id }) )));
	}

	const getVacancy = () => {
		fetch(`${BASE_URL}/vacancy`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
		.then(res => res.json())
		.then(res => setVacancies(res.map(x => ({ label: x.name, value: x.id }))));
	}

	useEffect(() => { 
		getStores();
		getVacancy();
	}, [])
	useEffect(() => {
		getAll();
	}, [store])

	const valid = store && vacancy;

	return (
		<AdminContainer active="vacancy_store">
			<div className="banners">
				<div className="banner-add">
					<Select className="banner-select" options={stores} value={store} onChange={(v) => setStore(v)} />
				</div>
				<div className="banner-add">
					<Select className="banner-select" options={vacancies} value={vacancy} onChange={(v) => setVacancy(v)} />
					<div className={'btn btn-alone ' + (valid ? '' : 'btn-disabled')}  onClick={valid ? () => add(store, vacancy) : undefined}>Добавить</div>
				</div>
				<div className="banners-table-container">
					<table className="banners-table">
						<thead>
							<tr>
								<th className="cactive">Активный</th>
								<th className="cview">Вакансия</th>
								<th className="cdel">Удалить</th>
								<th className="corder">Порядок</th>
							</tr>
						</thead>
						<tbody>
							{list.map((el, i) => (
								<tr key={el.id}>
									<td><input type="checkbox" checked={el.active} onChange={() => setActive(store, el._id, !el.active)}></input></td>
									<td>{el.vacancy ? el.vacancy.name :''}</td>
									<td className="pointer" onClick={() => del(store, el._id)}>X</td>
								<td><FiArrowDown className="pointer"  onClick={list.length > i+1 ? () => changePos(store, el._id, list[i+1]._id) : undefined} /> &nbsp;&nbsp;&nbsp;<FiArrowUp className="pointer" onClick={i > 0 ? () => changePos(store, el._id, list[i-1]._id): undefined} /></td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</AdminContainer>
	);
}

export default VacancyStore;