import React, { useState, useEffect } from "react";
import Main from "./main";
import Map from "../components/map";
import "./address.scss";
import Breadcrumb from "../components/breadcrumb";
import { BASE_URL, FB_URL, TELEGRAM, VIBER } from '../config/config';
import { ReactComponent as Phone } from '../assets/images/phone.svg';
import { phonepres } from '../helpers/num';


const Address = (props) => {

	const addresses = [
		{ name: 'Бахмут', address: 'вул. Свободи, 7'},
		{ name: 'Костянтинівка', address: 'вул. Інтернаціональна, 464'},
		{ name: 'Курахове', address: 'пр. Соборний, 20'},
		{ name: 'Селидове', address: 'вул. Карла Маркса, 1'},
	];
	// const htmlmap = `<iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d9641.342741826249!2d37.99419613234309!3d48.588059810494705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s%20lafar!5e0!3m2!1sen!2sua!4v1593590031979!5m2!1sen!2sua" width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>`;
	const htmlmap = `<iframe src="https://www.google.com/maps/embed?pb=!1m12!1m8!1m3!1d422156.4393570243!2d37.614303834567075!3d48.32961686169262!3m2!1i1024!2i768!4f13.1!2m1!1slafar!5e0!3m2!1sen!2sua!4v1621422489558!5m2!1sen!2sua" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`;
	const [stores, setStores] = useState(addresses);


	useEffect(() => {
		fetch(`${BASE_URL}/store/show`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => res.json())
			.then(res => {
				setStores(res || []);
			})
	}, [])


	const renderAddress = (a) => {
		return (
			<div className="address-item">
				<div className="address-item-horse"></div>
				<div className="address-item-data">
					<div className="address-item-name">
						{a.name}
					</div>
					<div className="address-item-address">
						{a.address}
					</div>
					<div className="address-item-phone">
						Телефон для передзамовлення:
					</div>
					<div className="address-item-phone">
						{phonepres(a.mobile)}
					</div>
					<div className="address-item-phone">
						{a.period || ''}
					</div>
				</div>
			</div>
		);
	}

	return (
		<Main excludeSN={true}>
			<Breadcrumb items={[{label: 'Головна', link: ''}, { label: 'Адреси / Контакти', link: 'address'}]} />
			<div className="map" dangerouslySetInnerHTML={{ __html: htmlmap }}></div>
			<div className="address-content">
				<div className="address-content-left">
					<div>0 800 600 810</div>
					<div>info@lafar.com.ua</div>
					<div className="address-sm-row">
						Ми в соціальних мережах:
						<a href="https://www.instagram.com/lafar.ua/?hl=uk" target="blank"><div className="address-sm-inst"></div></a>
						<a href={FB_URL} target="blank"><div className="address-sm-fb"></div></a>
						{/* <a href={TELEGRAM} target="blank"><div className="address-sm-telegram"></div></a>
						<a href={VIBER} target="blank"><div className="address-sm-viber"></div></a> */}
					</div>
				</div>
				<div className="address-content-right">
					{stores.map(x => renderAddress(x))}
				</div>
				<div className="address-mobile">
					<div className="address-phone">
						<Phone />&nbsp;
						<a href="tel:0800600810">0&nbsp;800&nbsp;600&nbsp;810</a>
					</div>
					<div className="address-mobile-email">
						<a href="mailto:info@lafar.com.ua">info@lafar.com.ua</a>
					</div>
					<div className="address-content-social">
						Ми в соціальних мережах:
						<a href="https://www.instagram.com/lafar.ua/?hl=uk" target="blank"><div className="address-sm-inst"></div></a>
						<a href={FB_URL} target="blank"><div className="address-sm-fb"></div></a>
					</div>
				</div>
			</div>
		</Main>
	);
};

export default Address;
