import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import AdminContainer from './admin_container';
import { BASE_URL } from '../../config/config';
import './admin.scss';

const Vacancy = () => {

	const [list, setList] = useState([]);
	const [vacancy, setVacancy] = useState({ name: '', group: ''});
	const [active, setActive] = useState(undefined);

	const add = (vacancy) => {
		fetch(`${BASE_URL}/vacancy`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ name: vacancy.name, group: vacancy.group, desc: vacancy.desc }) })
		.then(res => getVacancies())
	}

	const upd = (vacancy) => {
		fetch(`${BASE_URL}/vacancy`, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id: vacancy.id, name: vacancy.name, group: vacancy.group, desc: vacancy.desc }) })
		.then(res => getVacancies())
	}

	const del = (id) => {
		fetch(`${BASE_URL}/vacancy`, { method: 'DELETE', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id: id }) })
		.then(res => getVacancies())
	}

	const getVacancies = () => {
		fetch(`${BASE_URL}/vacancy`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
		.then(res => res.json())
		.then(res => setList(res));
	}

	// const setActive = (id, value) => {
	// 	fetch(`${BASE_URL}/store/active`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id: id, value: value }) })
	// 	.then(res => getStores())
	// }

	useEffect(() => { getVacancies();}, [])

	const validVacancy = vacancy.name;

	return (
		<AdminContainer active="vacancy">
			<div className="banners">
				<div className="banner-add">
					<div className="input-field">
						<div className="input-field-label">Название</div>
						<input type="text" value={vacancy.name} onChange={(e) => setVacancy({...vacancy, name: e.target.value})}></input>
					</div>
					<div className="input-field">
						<div className="input-field-label">Группа вакансий</div>
						<input type="text" value={vacancy.group} onChange={(e) => setVacancy({...vacancy, group: e.target.value})}></input>
					</div>
					<div className={'btn btn-alone ' + (validVacancy ? '' : 'btn-disabled')}  onClick={validVacancy ? () => add(vacancy) : undefined}>Добавить</div>
					<div className={'btn btn-alone ' + (validVacancy && vacancy.id ? '' : 'btn-disabled')}  onClick={validVacancy && vacancy.id ? () => upd(vacancy) : undefined}>Обновить</div>
				</div>
				<div className="banner-add">
					<div className="input-text">
							<textarea placeholder="Описание" type="text" value={vacancy.desc} onChange={(e) => setVacancy({...vacancy, desc: e.target.value})}></textarea>
						</div>
					</div>
				<div className="banners-table-container">
					<table className="banners-table">
						<thead>
							<tr>
								<th className="cname">Наименование</th>
								<th className="cgroup">Группа</th>
								<th className="cdesc">Описание</th>
							</tr>
						</thead>
						<tbody>
							{list.map((el, i) => (
								<tr key={el.id} onClick={() => { setActive(el.id); setVacancy(el); } } className={active === el.id ? 'active' : ''}>
									<td>{el.name ? el.name :''}</td>
									<td>{el.group ? el.group :''}</td>
									<td>{el.desc ? el.desc :''}</td>
									<td className="pointer" onClick={() => del(el.id)}>X</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</AdminContainer>
	);
}

export default Vacancy;