import React, { useEffect, useState } from "react";
import Main from "./main";
import Banner from "../components/banners/banner";
import Option from "../components/option";
import "./home.scss";
import Carousel from "./carousel";
import { BASE_URL } from '../config/config';

const Home = () => {

	const [media, setMedia] = useState(undefined);
	const [links, setLinks] = useState([]);

	useEffect(() => {
		fetch(`${BASE_URL}/banner/show`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => res.json())
			.then(res => {
				setMedia(res || []);
			})
		fetch(`${BASE_URL}/home/show`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => res.json())
			.then(res => {
				setLinks(res || []);
			})
	}, [])

	const renderCarousel = () => {
		let res;
		if (media) {
			if (media.length === 0) res = (<Carousel banners={[<Banner image="image11" />, <Banner image="image10" />, <Banner image="image9" />, <Banner image="image4" />, <Banner image="image5" />, <Banner image="image7" />]} />);
			else res = (<Carousel banners={media.filter(b => !!b && !!b.path).map(b => (<Banner url={b.path} />))} />);
		} else res = (<Carousel banners={[]} />);
		return res;
	}

	return (
		<Main>
			{/* <Carousel banners={[<Banner1 />, <Banner2 />, <Banner3 />]} /> */}
			{/* <Carousel banners={[<Banner image="image8" />,<Banner image="image4" />, <Banner image="image5" />, <Banner image="image7" />]} /> */}
			{renderCarousel()}
			<div className='options-container'>
				{links.length > 0 &&
					links.map(l => (<Option mayResize label={l.desc} imagePath={l.imgPath} textImagePath={l.capPath} link={l.link} />))
				}
				{links.length === 0 &&
					<>
						<Option mayResize label="ЧОМУ МИ?" image="why-we" link="we" />
						<Option mayResize label='ONLINE МАГАЗИН' image='store' link="store" />
						<Option mayResize label='АКЦІЇ ТА ПРОПОЗИЦІЇ' image='promotions' link="promotions" />
						<Option mayResize label='ВАКАНСІЇ' image='vacancy' link="vacancy" />
						<Option mayResize label='ВЛАСНЕ ВИРОБНИЦТВО' image='manufacture' link="manufacture" />
						<Option mayResize image='cafe' link="cafe" textImage='option-lacafe' />
						<Option mayResize label='ОСОБИСТИЙ КАБІНЕТ' image='own-cabinet' link="https://my-lafar.abmloyalty.app/profile/edit" />
						{/* <Option mayResize label='БОНУСНА ПРОГРАМА' image='bonuses' link="bonuses" /> */}
						<Option mayResize label='АДРЕСИ' image='address' link='address' />
					</>
				}
			</div>
		</Main>
	);
};

export default Home;
