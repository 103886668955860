import React from 'react';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../config/config';
import './option.scss';

const OuterLink = (props) => {

	return (
		<a target="_blank" href={props.href} rel="noopener noreferrer" className={'option-link option-link-' + props.type}>
			{props.children}
		</a>
	)
}

const Option = (props) => {

	const Comp = (props.link && (props.link.startsWith('http://') || props.link.startsWith('https://'))) ? OuterLink : Link;
	const textSize = props.label && props.label.length <= 30 ? '' : 'option-text-2';
	return (
		<Comp to={props.link} href={props.link} className={'option-link option-link-' + props.type}>
			<div className="option-space" />
			<div className={props.type ? 'option option-' + props.type : 'option'}>
				<div className={'option-content ' + (props.mayResize ? '' : 'option-content-big')} onClick={props.onClick}>
					<div className={`option-image option-${props.image}`} style={{ backgroundImage: props.imagePath ? `url(${BASE_URL}/images/${props.imagePath})` : undefined }} ></div>
					<div className={`option-text ${textSize} option-text-${props.color} ${props.imagePath || props.image ? '' : 'option-text-noimage'}`}>
						{(props.label && !props.textImagePath) &&
							<div>{props.label}</div>
						}
						{(props.textImage || props.textImagePath) &&
							<div className={'option-textimage ' + props.textImage} style={{ backgroundImage: props.textImagePath ? `url(${BASE_URL}/images/${props.textImagePath})` : undefined }}></div>
						}
					</div>
				</div>
			</div>
		</Comp>
	);
}

export default Option;