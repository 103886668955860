import React from 'react';
import { Link } from 'react-router-dom';
import './header.scss';
import { getMobileLink } from '../config/config';
import { connect } from 'react-redux';
import { toggleTheme } from '../reducers/reducer';
import Slider from './slider';

const Header = (props) => {

	const mobile = window.screen.width <= 400;
	const iconW	= mobile ? 14 : 20;

	return (
		<div className="header">
			<div className="header-left">
			<div className={'menu-button ' + (props.showMenu ? 'menu-button-rot' : '')} onClick={() => props.doShowMenu()}>
				<svg width="37" height="22" viewBox="0 0 37 22" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect width="37" height="4" rx="2" fill="#DDEA73"/>
					<rect y="9" width="37" height="4" rx="2" fill="#DDEA73"/>
					<rect y="18" width="37" height="4" rx="2" fill="#DDEA73"/>
				</svg>
			</div>
			<Link to="">
				<div className={'logo logo-' + props.theme} ></div>
			</Link>
			<div className="logo-desc">МЕРЕЖА МАРКЕТIВ</div>
			</div>
			<div className="header-right">
				<div className="header-phone-container">
					<div className="header-phone-support">ІНФОРМАЦІЙНА ПІДТРИМКА</div>
					<div className="header-phone-phone"><a href="tel:0800600810">0&nbsp;800&nbsp;600&nbsp;810</a></div>
					<div className="header-phone-time">ПРАЦЮЄ&nbsp;З&nbsp;08:00-18:00</div>
				</div>
				{/* <div className="header-app"><a href={getMobileLink()}>МОБІЛЬНИЙ ДОДАТОК</a></div> */}
				{/* <a href={getMobileLink()}>
					<div className="header-mobile"><span className="header-icon-text">МОБІЛЬНИЙ ДОДАТОК</span></div>
				</a> */}
				<Link to="address">
					<div className="header-location"><span className="header-icon-text">АДРЕСИ</span></div>
				</Link>
				<Link to="/basket" className="a-basket">
					<div className="header-favorites">
						{props.fav.length > 0 && 
							<>
								<div className="header-cnt-container">
									<svg height={iconW} width={iconW}>
										<circle cx={iconW/2} cy={iconW/2} r={iconW/2} stroke="black" stroke-width="0" fill="var(--gtor)" />
									</svg>
									<div className="header-cnt">{props.fav.length}</div>
									
								</div>
							</>
						}
						<span className="header-icon-text">ОБРАНЕ</span>
					</div>
				</Link>
				<Link to="/basket" className="a-basket">
					<div className="header-basket">
						{props.basket.length > 0 &&
							<div className="header-cnt-container">
								<svg height={iconW} width={iconW}>
									<circle cx={iconW/2} cy={iconW/2} r={iconW/2} stroke="black" stroke-width="0" fill="var(--gtor)" />
								</svg>
								<div className="header-cnt">{props.basket.length}</div>
							</div>
						}
						<span className="header-icon-text">КОРЗИНА</span>
					</div>
				</Link>
				<Link to="/conditions">
					<div className="header-delivery"><span className="header-icon-text">ОПЛАТА ТА ДОСТАВКА</span></div>
				</Link>
				{/* <a href="tel:0800600810"><div className="header-phone"></div></a> */}
				<Slider checked={props.theme === 'white'} toggle={() => props.toggleTheme()} />
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	basket: state.basket,
	fav: state.fav,
	theme: state.theme
});

const mapDispatchToProps = (dispatch) => ({
	toggleTheme: () => dispatch(toggleTheme()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Header);