import React from "react";
import Main from "./main";
import Breadcrumb from "../components/breadcrumb";
import Construction from '../components/construction';
import './questions.scss';
import { connect } from 'react-redux';

const Questions = ({ theme }) => {
	return (
		<Main>
			<Breadcrumb items={[{label: 'Головна', link: ''}, { label: 'Питання та відповіді', link: 'questions'}]} />
            <div className="questions">
                <div className="q-header">
                    <div className="q-header-left">
                        <div className="q-header-title">
                            ПИТАННЯ ТА ВІДПОВІДІ
                        </div>
                        <div className="q-title-text">
                            <div className="q-title">
                                Як отримати картку "Зебра"?
                            </div>
                            <div className="q-text">
                                Картку можна отримати, заповнивши паперову
                                <br />
                                анкету на касі та залишивши її у касира для 
                                <br />
                                подальшого внесення данних в базу.
                            </div>
                        </div>
                    </div>
                    <div className={'q-header-right ' + (theme === 'black' ? 'q-header-right-b' : 'q-header-right-w')}>
                        <img alt="" src={'url()'} />
                    </div>
                </div>
                <div className="q-addin">
                    Увага! Анкета має бути заповнена належним чином, тобто заповнені обов'язкові 
                    <br />
                    поля, позначені "*"! Якщо анкета заповнена неналежним чином, використання 
                    <br />
                    бонусів неможливо.
                </div>
                <div className="q-title-text q-short">
                    <div className="q-title">
                        Як діяти при пошкодженні або втраті картки?
                    </div>
                    <div className="q-text">
                        В разі, коли картка пошкоджена або втрачена, необхідно отримати 
                        <br />
                        нову картку на касі.
                    </div>
                </div>

                <div className="q-lane">
                    <div className="main-container">
                        <div className="q-lane-title">
                            Що надає мені картка «Зебра» та участь
                            <br />
                            у програмі лояльності?
                        </div>
                        <ul>
                            <li className="q-lane-item">Можливість накопичувати бонуси та заощаджувати на кожній покупці;</li>
                            <li className="q-lane-item">Можливість отримувати персональні пропозиції та додаткові бонуси;</li>
                            <li className="q-lane-item">Можливість зараховувати дрібну решту на картку</li>
                        </ul>
                    </div>
                </div>

                <div className="q-title-text q-short q-lane-margin">
                    <div className="q-title">
                        Як нараховуються бонуси?
                    </div>
                    <div className="q-text">
                        Бонуси нараховуються на будь-яку покупку в розмірі <span className="q-text-emph">1% від суми чеку.&nbsp;</span>
                        <br />
                        Бонуси НЕ нараховуються і НЕ списуються при купівлі тютюнових 
                        <br />
                        виробів, алкогольних напоїв та акційних товарів.
                    </div>
                </div>
                <div className="q-wrap">
                    <div className="q-wrap-left">
                        <div className="q-title-text">
                            <div className="q-title">
                                Як перевірити бонуси на рахунку?
                            </div>
                            <div className="q-text">
                                Стан бонусного рахунку можна перевірити в чеку.
                            </div>
                        </div>
                    </div>
                    <div className={'q-wrap-right ' + (theme === 'black' ? 'q-wrap-right-b' : 'q-wrap-right-w')}>
                        <img alt="" />
                    </div>
                </div>
                <div className="q-title-text q-short">
                    <div className="q-title">
                        Як довго зберігаються бонуси?
                    </div>
                    <div className="q-text">
                        Бонуси зберігаються протягом <span className="q-text-emph">3 місяців</span> з моменту їх нарахування.
                        <br />
                        Якщо в цей період часу не здійснювалось жодної покупки з карткою-
                        <br />
                        бонуси анулюються.
                    </div>
                </div>
                <div className="q-title-text q-short">
                    <div className="q-title">
                        Як скористатися бонусами?
                    </div>
                    <div className="q-text">
                        Бонуси можна використовувати вже на наступну покупку, але в розмірі
                        <br />
                        не більше, ніж 90% від суми чеку.
                    </div>
                </div>
            </div>
		</Main>
	);
};

const mapStateToProps = (state) => ({
	theme: state.theme
});

export default connect(mapStateToProps)(Questions);
