import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import AdminContainer from './admin_container';
import { BASE_URL } from '../../config/config';
import './admin.scss';

const Store = () => {

	const [media, setMedia] = useState([]);
	const [list, setList] = useState([]);
	const [chosenMedia, setChosenMedia] = useState(undefined);
	const [store, setStore] = useState({ name: '', address: '' });
	const [storeId, setStoreId] = useState();

	const addEdit = (id, store, storeId = undefined) => {
		fetch(`${BASE_URL}/store`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id: id, name: store.name, address: store.address, mobile: store.mobile, period: store.period, storeId: storeId }) })
		.then(res => getStores())
	}

	const del = (id) => {
		fetch(`${BASE_URL}/store`, { method: 'DELETE', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id: id }) })
		.then(res => getStores())
	}

	const getStores = () => {
		fetch(`${BASE_URL}/store`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
		.then(res => res.json())
		.then(res => setList(res));
	}

	const changePos = (id1, id2) => {
		fetch(`${BASE_URL}/store/changeOrder`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id1: id1, id2: id2 }) })
		.then(res => getStores())
	}

	const setActive = (id, value) => {
		fetch(`${BASE_URL}/store/active`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id: id, value: value }) })
		.then(res => getStores())
	}

	useEffect(() => { getStores();}, [])
	useEffect(() => {
		fetch(`${BASE_URL}/media`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
		.then(res => res.json())
		.then(res => { 
			const listIds = list.filter(x => x.media).map(x => x.media.id);
			setMedia(res.map(x => ({ value: x.id, label: x.name })))
		});
	}, [list]);

	const validStore =  store.name && store.address;

	return (
		<AdminContainer active="stores">
			<div className="banners">
				<div className="banner-add">
					<Select className="banner-select" options={media} value={chosenMedia} onChange={(v) => setChosenMedia(v)} />
					<div className="input-field">
						<div className="input-field-label">Название</div>
						<input type="text" value={store.name} onChange={(e) => setStore({...store, name: e.target.value})}></input>
					</div>
					<div className="input-field">
						<div className="input-field-label">Адрес</div>
						<input type="text" value={store.address} onChange={(e) => setStore({...store, address: e.target.value})}></input>
					</div>
					<div className="input-field">
						<div className="input-field-label">Телефон</div>
						<input type="text" value={store.mobile} onChange={(e) => setStore({...store, mobile: e.target.value})}></input>
					</div>
					<div className="input-field">
						<div className="input-field-label">Время</div>
						<input type="text" value={store.period} onChange={(e) => setStore({...store, period: e.target.value})}></input>
					</div>
					<div className={'btn ' + (validStore ? '' : 'btn-disabled')}  onClick={validStore ? () => addEdit(chosenMedia ? chosenMedia.value : undefined, store) : undefined}>Добавить</div>
					<div className={'btn btn-alone ' + ((validStore && storeId) ? '' : 'btn-disabled')}  onClick={(validStore && storeId) ? () => addEdit(chosenMedia ? chosenMedia.value : undefined, store, storeId) : undefined}>Обновить</div>
				</div>
				<div className="banners-table-container">
					<table className="banners-table">
						<thead>
							<tr>
								<th className="cactive">Активный</th>
								<th className="cview">Вид</th>
								<th className="cname">Название</th>
								<th className="cname">Адрес</th>
								<th className="cname">Телефон</th>
								<th className="cname">Время работы</th>
								<th className="cdel">Удалить</th>
								<th className="corder">Порядок</th>
							</tr>
						</thead>
						<tbody>
							{list.map((el, i) => (
								<tr key={el.id} onClick={() => { setStoreId(el.id); setStore({ name: el.name, address: el.address, period: el.period, mobile: el.mobile }); setChosenMedia((el.media && el.media.id) ? media.find(m => '' + m.value === '' + el.media.id) : undefined); } }>
									<td><input type="checkbox" checked={el.active} onChange={() => setActive(el.id, !el.active)}></input></td>
									<td>
										{(el.media && el.media.path) &&
											<img src={BASE_URL + '/images/' + el.media.path}></img>
										}
									</td>
									<td>{el.name ? el.name :''}</td>
									<td>{el.address ? el.address :''}</td>
									<td style={{ whiteSpace: 'nowrap' }}>{el.mobile ? el.mobile :''}</td>
									<td>{el.period ? el.period :''}</td>
									<td className="pointer" onClick={() => del(el.id)}>X</td>
									<td><FiArrowDown className="pointer"  onClick={list.length > i+1 ? () => changePos(el.id, list[i+1].id) : undefined} /> &nbsp;&nbsp;&nbsp;<FiArrowUp className="pointer" onClick={i > 0 ? () => changePos(el.id, list[i-1].id): undefined} /></td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</AdminContainer>
	);
}

export default Store;