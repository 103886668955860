import React from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import './pages.scss';


const Pages = ({ num, len, setNum, cb }) => {

	return len === 0 ? (<div></div>) : (
		<div className="pages">
			<FaArrowLeft onClick={() => { setNum(Math.max(num - 1, 1)); if (cb) cb(); }} />
			<div className="pages-number">{num} / {len}</div>
			<FaArrowRight onClick={() => { setNum(Math.min(num + 1, len)); if (cb) cb(); }} />
		</div>
	);
}

export default Pages;