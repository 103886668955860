import React, { useState, useEffect, useRef, Suspense } from 'react';
import AdminContainer from './admin_container';
import './admin.scss';
// import JoditEditor from "jodit-react";
import { BASE_URL } from '../../config/config';
const JoditEditor = React.lazy(() => import('jodit-react'));


const We = () => {
	const editor = useRef(null)
	const [content, setContent] = useState('')
	const [mob, setMob] = useState(false)
	
	const config = {
		readonly: false, // all options from https://xdsoft.net/jodit/doc/
		height: '100%',
		controls: { font: { list: { "Roboto": 'Roboto' }}}
	}

	useEffect(() => {
		get();
	}, [])
	useEffect(() => {
		get();
	}, [mob])

	const get = () => {
		fetch(`${BASE_URL}/page/we${mob ? '_mob' : ''}`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => res.json())
			.then(res => setContent(res));
	}

	const upd = () => {
		fetch(`${BASE_URL}/page/we${mob ? '_mob' : ''}`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ text: content }) })
			// .then(res => get());
	}
	
	return (
		<AdminContainer active="we">
			<div className="we">
				<div className="we-commands">
					<div className="we-select-group">
						<div className={'we-select ' + (!mob ? 'we-select-active' : '' ) } onClick={() => setMob(false)}>Desktop</div>
						<div className={'we-select ' + (mob ? 'we-select-active' : '' ) } onClick={() => setMob(true)}>Mobile</div>
					</div>
					<div className="we-btn">
						<div className="btn btn-alone" onClick={() => upd()}>Обновить</div>
					</div>
				</div>
				<Suspense fallback={<div>Loading... </div>}>
					<JoditEditor
						ref={editor}
						value={content}
						config={config}
						tabIndex={1} // tabIndex of textarea
						onBlur={e => {
							setContent(e.target.innerHTML);
						}} // preferred to use only this option to update the content for performance reasons
						// onChange={newContent => setContent(newContent)}
					/>
				</Suspense>
			</div>
		</AdminContainer>
	);
}

export default We;