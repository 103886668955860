import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/header';
import Menu from '../components/menu';
import './main.scss';
import { FB_URL, TELEGRAM, VIBER } from '../config/config';
import { FiArrowUp } from 'react-icons/fi';
import { runSnow } from '../components/snow';
import { connect } from 'react-redux';


function useInterval(callback, delay) {
	const savedCallback = useRef();

	// Remember the latest function.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		function tick() {
			savedCallback.current();
		}
		if (delay !== null) {
			let id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
}

const Main = (props) => {

	const [showMenu, setShowMenu] = useState(false);
	const [showZebra, setShowZebra] = useState(false);
	const [zebraTop, setZebraTop] = useState(0);
	const [zebraLeft, setZebraLeft] = useState(-150);
	const [zebraSide, setZebraSide] = useState(0);

	const [showUp, setShowUp] = useState(false);

	useEffect(() => {
		window.addEventListener('scroll', showUpArrow);
		// runSnow();
		return () => {
			window.removeEventListener('scroll', showUpArrow);
		}
	}, [])

	useInterval(() => {
		if (!showZebra) { 
			if (zebraSide === 0 || zebraSide === 2) setZebraLeft(0);
			if (zebraSide === 1 || zebraSide === 3) setZebraTop(0);
		} else {
			if (zebraSide === 0 || zebraSide === 2) setZebraLeft(-150);
			if (zebraSide === 1 || zebraSide === 3) setZebraTop(-150);
			setTimeout(() => {
				const newSide = (zebraSide + 2) % 4;
				setZebraSide(newSide);
				if (newSide === 0 || newSide === 2) {
					setZebraTop(Math.floor(Math.random() * Math.floor(window.innerHeight - 200)));
					setZebraLeft(-150);
				}
				if (newSide === 1 || newSide === 3) {
					setZebraLeft(Math.floor(Math.random() * Math.floor(window.innerWidth - 200)));
					setZebraTop(-150);
				}
			}, 2000);
		}
		setShowZebra(!showZebra);
	}, 5000)

	const renderRights = () => {
		return (
			<div className="main-footer-row-2-mob">
				<div>
					Усі права захищені
				</div>
				<div>
					«Мережа маркетів Лафар»
				</div>
				<div>
					© 2020
				</div>
			</div>
		);
	}

	const zebraProp = zebraSide <= 1 ? { left: `${zebraLeft}px`, top: `${zebraTop}px`, transform: `rotate(${zebraSide === 0 ? 0 : 90}deg)` } : 
		{ right: `${zebraLeft}px`, bottom: `${zebraTop}px`, transform: `rotate(${zebraSide === 2 ? 0 : 90}deg) rotateY(180deg)` };

	let zclass = `zebra${zebraSide === 0 || zebraSide === 2 ? 'h' : 'v'}`;
	zclass += zebraSide === 0 ? ` ${zclass}-left` : ` ${zclass}-right`;

	const showUpArrow = () => {
		setShowUp(window.pageYOffset > 100);
	}

	return (
		<div className={'main ' + (props.theme === 'white' ? 'main-white' : '')} onScroll={() => showUpArrow()}>
			<div className={'main-container ' + (props.blur ? 'main-container-blur' : '')}>
				<Header showMenu={showMenu} doShowMenu={() => setShowMenu(true)} />
				<Menu showMenu={showMenu} hideMenu={() => setShowMenu(false)} />
				{props.children}
			</div>
			<div className={zclass} style={zebraProp}><div className={`zebra1 ${'zebra-an' + zebraSide}`}></div></div>
			{showUp &&
				<FiArrowUp className="arrow-top" onClick={() => { window.scrollTo(0, 0); }} />
			}
			<div className={'main-footer ' + (props.theme === 'black' ? 'main-footer-black' : 'main-footer-white')}>
				{props.store &&
					<div className="main-footer-store">
						Ціни, що представлені на сайті, не відрізняються від цін магазинів
					</div>
				}
				{!props.excludeSN &&
					<div className="main-footer-row">
						<div className="main-footer-row-left">
							Ми в соціальних мережах:
							<a href="https://www.instagram.com/lafar.ua/?hl=uk" target="blank"><div className="main-footer-inst"></div></a>
							<a href={FB_URL} target="blank"><div className="main-footer-fb"></div></a>
							{/* <a href={TELEGRAM} target="blank"><div className="main-footer-telegram"></div></a> */}
							{/* <a href={VIBER} target="blank"><div className="main-footer-viber"></div></a> */}
						</div>
						<div className="main-footer-row-right">
							Усі права захищені © 2020  «Мережа маркетів Лафар»
						</div>
						{renderRights()}
					</div>
				}
				{props.excludeSN &&
					<>
						<div className="main-footer-row-2">
							<div>
								Усі права захищені © 2020  «Мережа маркетів Лафар»
							</div>
						</div>
						{renderRights()}
					</>
				}
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	theme: state.theme
});

export default connect(mapStateToProps, {})(Main);