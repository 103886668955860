import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const AdminContainer = (props) => {

	const pages = {
		media: 'Медиа ресурсы',
		banner: 'Баннеры',
		home: 'Главная',
		promotion: 'Акции',
		cafe: 'Кафе',
		manufacture: 'Производство',
		stores: 'Магазины',
		vacancy: 'Вакансии',
		vacancy_store: 'Вакансии магазинов',
		we: 'Ми',
		delivery: 'Доставка',
	};

	return (
		<div className='admin-container'>
			{!props.isLoggedIn &&
				<Redirect to='/admin' />
			}
			<div className="admin-header">
				Администрирование сайта - {pages[props.active]}
			</div>
			<div className="admin-panel">
				<div className="admin-menu">
					{Object.keys(pages).map(p => (
						<Link to={`/admin/${p}`} key={p}>
							<div className={`admin-menu-item ${props.active === p ? 'admin-menu-item-active' : ''}`}>{pages[p]}</div>
						</Link>
					))}
				</div>
				<div className="admin-body">
					{props.children}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	isLoggedIn: state.isLoggedIn
});

export default connect(mapStateToProps)(AdminContainer);
