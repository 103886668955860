import React from 'react';
import './breadcrumb.scss';
import { Link } from 'react-router-dom';

const Breadcrumb = (props) => {

	return (
		<div className="breadcrumb">
			{props.items.map((x, i) => (
				<Link to={x.link || ''}>
					<span className={i === props.items.length - 1 ? 'breadcrumb-current' : 'breadcrumb-prev'} onClick={() => { if (x.callback) x.callback(); }}  >{i > 0 ? ' | ' : ''}{x.label}</span>
				</Link>
			))}
		</div>
	);
}

export default Breadcrumb;