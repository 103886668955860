import React, { useState, useEffect, useRef } from "react";
import './carousel.scss';


const Carousel2 = (props) => {

	return (
		<div className="carousel2-container">
			<div className="arrow-left" onClick={() => props.left ? props.left() : undefined}></div>
			{props.banners.map((x, i) => (
				<div key={i} >
					{x}
				</div>
			))}
			<div className="arrow-right" onClick={() => props.right ? props.right() : undefined}></div>
		</div>
	);
};

export default Carousel2;
