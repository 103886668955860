import React from 'react';
import './slider.scss';


const Slider = ({ checked, toggle }) => {

	return (
		<div className="switch-container">
			<label class="switch">
				<input type="checkbox" checked={checked} onClick={() => toggle()} />
				<span class="slider round"></span>
			</label>
			<div className="switch-labels">
				<div className="slider-label slider-label-black">Black</div>
				<div className="slider-label slider-label-white">White</div>
			</div>
		</div>
	)
}

export default Slider;