import React from 'react';
import AdminContainer from './admin_container';
import './admin.scss';

const Users = () => {
	return (
		<AdminContainer>
			<div className='label'>
				Users
			</div>
		</AdminContainer>
	);
}

export default Users;