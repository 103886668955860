import React, { useEffect } from 'react';
import Home from './home';
import We from './we';
import Store from './store';
import Basket from './basket';
import Vacancy from './vacancy';
import Bonuses from './bonuses';
import Questions from './questions';
import Promotions from './promotions';
import Cabinet from './cabinet';
import Manufacture from './manufacture';
import Cafe from './cafe';
import Address from './address';
import Conditions from './conditions';
import AdminHome from './admin';
import AdminUsers from './admin/admin_users';
import AdminMedia from './admin/admin_media';
import AdminBanner from './admin/admin_banner';
import AdminMain from './admin/admin_home';
import AdminPromotion from './admin/admin_promotion';
import AdminManufacture from './admin/admin_manufacture';
import AdminStores from './admin/admin_store';
import AdminCafe from './admin/admin_cafe';
import AdminVacancy from './admin/admin_vacancy';
import AdminVacancyStore from './admin/admin_vacancy_store';
import AdminWe from './admin/admin_we';
import AdminDelivery from './admin/admin_delivery';
import { Switch, Route } from 'react-router-dom';
import { BASE_URL } from '../config/config';
import { connect } from 'react-redux';
import { basketSet, choose, toggleTheme } from "../reducers/reducer";


const AppContainer = (props) => {

	useEffect(() => {
		const s = sessionStorage.getItem('store');
		const b = JSON.parse(localStorage.getItem('basket') || '[]');
		const theme = localStorage.getItem('theme') || 'black';
		props.toggleTheme(theme);
		updBasket(b, s);

		if (s) {
			fetch(`${BASE_URL}/store`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => res.json())
			.then(res => {
				props.handleSetStore(res.find(x => x.id === s));
			});
		}

	}, []);
	
	useEffect(() => {
		if (props.store) updBasket()
	}, [props.store]);

	const updBasket = (basket, store) => {
		const b = basket || (props.basket && props.basket.map(x => ({ id: x.article.id, qty: x.qty })));
		const s = store || (props.store && props.store.id);
		if (b.length > 0) {
			fetch(`${BASE_URL}/article/info/${b.map(x => x.id).join(',')}?store=${s}`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => res.json())
			.then(r => {
				const basket = b.map(x => ({ article: Array.isArray(r) ? r.find(a => a.id === x.id ) : r, qty: x.qty }));
				props.handleSetBasket(basket);
			});
		}
	}

	return (
		<Switch>
			<Route exact path="/" component={Home} />
			<Route path="/we" component={We} />
			<Route path={['/store', '/store/**']} component={Store} />
			<Route path="/basket" component={Basket} />
			<Route path="/vacancy" component={Vacancy} />
			<Route path="/bonuses" component={Bonuses} />
			<Route path="/questions" component={Questions} />
			<Route path="/promotions" component={Promotions} />
			<Route path="/cabinet" component={() => { window.location.href = 'https://my-lafar.abmloyalty.app/profile/edit'; return null; }} />
			<Route path="/manufacture" component={Manufacture} />
			<Route path="/cafe" component={Cafe} />
			<Route path="/address" component={Address} />
			<Route path="/conditions" component={Conditions} />
			<Route exact path="/admin" component={AdminHome} />
			<Route path="/admin/users" component={AdminUsers} />
			<Route path="/admin/media" component={AdminMedia} />
			<Route path="/admin/banner" component={AdminBanner} />
			<Route path="/admin/home" component={AdminMain} />
			<Route path="/admin/cafe" component={AdminCafe} />
			<Route path="/admin/promotion" component={AdminPromotion} />
			<Route path="/admin/manufacture" component={AdminManufacture} />
			<Route path="/admin/stores" component={AdminStores} />
			<Route path="/admin/vacancy" component={AdminVacancy} />
			<Route path="/admin/delivery" component={AdminDelivery} />
			<Route path="/admin/vacancy_store" component={AdminVacancyStore} />
			<Route path="/admin/we" component={AdminWe} />
			<Route exact path="/*" component={Home} />
		</Switch>
	);
}

const mapStateToProps = (state) => ({
	store: state.store,
	basket: state.basket
});
const mapDispatchToProps = (dispatch) => ({
	handleSetBasket: (b) => dispatch(basketSet(b)),
	handleSetStore: (b) => dispatch(choose(b)),
	toggleTheme: (b) => dispatch(toggleTheme(b)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
