import React, {useState, useEffect} from "react";
import Main from "./main";
import Breadcrumb from "../components/breadcrumb";
import Construction from '../components/construction';
import { Link } from 'react-router-dom';
import "./vacancy.scss";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Option from "../components/option";
import { BASE_URL } from '../config/config';

const text = `
<div class="vacacy-text">
	ВІК: 18-60 років
	<br />
	<br />
	Відправляйте своє рєзюме на пошту  <a href="mailto:nataliya.ekhalo@lafar.com.ua">nataliya.ekhalo@lafar.com.ua</a> або телефонуйте за номером <a href="tel:0501004027">0&nbsp;501&nbsp;004&nbsp;027</a>
	<br />
	<br />
	В темі повідомлення необхідно написати бажану посаду та місто,  не забудьте залишити свій номер телефону 
</div>
`
const vacancies = [
	{ name: 'Бахмут', image: 'store0', list: [
		{ name: 'Керуючий Магазину', text: text }, 
		{ name: 'Адміністратор кафе', text: text }, 
		{ name: 'Касир', text: text }, 
		{ name: 'Продавець', text: text }, 
		{ name: 'Повар', list: [
			{ name: 'Повар коптильного цеху', text: text }, 
			{ name: 'Повар холодного/горячого цеху', text: text }, 
		]}, 
		{ name: 'Пекар', text: text }, 
		{ name: 'Піцейола', text: text },
		{ name: 'Промоутер', text: text },
	] }, 
	{ name: 'Курахове', image: 'store1', list: [
		{ name: 'Пекар', text: text },
		{ name: 'Повар', list: [
			{ name: 'Повар коптильного цеху', text: text }, 
		]}, 
		{ name: 'Піцейола', text: text },
		{ name: 'Промоутер', text: text },
	]}, 
	{ name: 'Костянтинівка', image: 'store2', list: [
		{ name: 'Оператор', text: text }, 
		{ name: 'Касир', text: text }, 
		{ name: 'Продавець', list: [
			{ name: 'Продавець молочного відділу', text: text }, 
			{ name: 'Продавець овочевого відділу', text: text }, 
			{ name: 'Продавець торгового залу', text: text },
			{ name: 'Продавець м\'ясного відділу', text: text },
			{ name: 'Продавець делікатесного відділу', text: text }
		]}, 
		{ name: 'Вантажник', text: text }, 
		{ name: 'Повар', list: [
			{ name: 'Повар коптильного цеху', text: text }, 
		]}, 
		{ name: 'Піцейола', text: text },
		{ name: 'Промоутер', text: text },

		// { name: 'Ревізор', text: text }, 
		// { name: 'Комірник', text: text }, 
		// { name: 'Фасувальник', text: text },
		// { name: 'Прибиральниця', text: text },
		// { name: 'Кухар', text: text },
		// { name: 'Пекар', text: text }, 
		// { name: 'Завгосп', text: text }, 
		// { name: 'Охоронець', text: text }, 
		// { name: 'Комплектовщик', text: text }, 
		// { name: 'Грузчик', text: text }, 
		// { name: 'Начальник охорони', text: text }, 
		// { name: 'Оператор відео', text: text }, 
		// { name: 'Піцейола', text: text }, 
	] },
	{ name: 'Селидове', list: [
		{ name: 'Керуючий Магазину', text: text }, 
		{ name: 'Адміністратор залу', text: text }, 
		{ name: 'Адміністратор кафе', text: text },
		{ name: 'Оператор', text: text }, 
		{ name: 'Ревізор', text: text }, 
		{ name: 'Начальник охорони', text: text }, 
		{ name: 'Охоронець', text: text }, 
		{ name: 'Касир', text: text }, 
		{ name: 'Продавець', list: [
			{ name: 'Продавець молочного відділу', text: text }, 
			{ name: 'Продавець овочевого відділу', text: text }, 
			{ name: 'Продавець торгового залу', text: text },
			{ name: 'Продавець делікатесного відділу', text: text }
		]}, 
		{ name: 'Пекар', text: text }, 
		{ name: 'Повар', list: [
			{ name: 'Повар коптильного цеху', text: text }, 
			{ name: 'Повар холодного/горячого цеху', text: text }, 
		]}, 
		{ name: 'Піцейола', text: text },
		{ name: 'Вантажник', text: text }, 
		{ name: 'Комірник', text: text }, 
		{ name: 'Завгосп', text: text }, 
		{ name: 'Прибиральниця', text: text },
		{ name: 'Фасувальник', text: text },

		// { name: 'Касир', text: text }, 
		// { name: 'Продавець', list: [
		// 	{ name: 'Продавець молочного відділу', text: text }, 
		// 	{ name: 'Продавець овочевого відділу', text: text }, 
		// 	{ name: 'Продавець торгового залу', text: text },
		// 	{ name: 'Продавець делікатесного відділу', text: text }
		// ]}, 
		// { name: 'Начальник охорони', text: text }, 
		// { name: 'Охоронець', text: text }, 
		// { name: 'Пекар', text: text }, 
		// { name: 'Повар', list: [
		// 	{ name: 'Повар коптильного цеху', text: text }, 
		// 	{ name: 'Повар холодного/горячого цеху', text: text }, 
		// ]}, 
		// { name: 'Комірник', text: text }, 
		// { name: 'Ревізор', text: text }, 
		// { name: 'Оператор', text: text }, 
		// { name: 'Охорона', text: text }, 
		// { name: 'Комплектовщик', text: text }, 
		// { name: 'Грузчик', text: text }, 
		// { name: 'Піцейола', text: text }, 
		// { name: 'Бригадир', text: text }, 
	] },
	{ name: 'Авдіївка', list: [
		{ name: 'Адміністратор залу', text: text }, 
		{ name: 'Адміністратор кафе', text: text },
		{ name: 'Оператор', text: text }, 
		{ name: 'Ревізор', text: text }, 
		{ name: 'Начальник охорони', text: text }, 
		{ name: 'Охоронець', text: text }, 
		{ name: 'Касир', text: text }, 
		{ name: 'Продавець', list: [
			{ name: 'Продавець молочного відділу', text: text }, 
			{ name: 'Продавець овочевого відділу', text: text }, 
			{ name: 'Продавець торгового залу', text: text },
			{ name: 'Продавець делікатесного відділу', text: text }
		]}, 
		{ name: 'Пекар', text: text }, 
		{ name: 'Повар', list: [
			{ name: 'Повар коптильного цеху', text: text }, 
			{ name: 'Повар холодного/горячого цеху', text: text }, 
		]}, 
		{ name: 'Піцейола', text: text },
		{ name: 'Вантажник', text: text }, 
		{ name: 'Комірник', text: text }, 
		{ name: 'Завгосп', text: text }, 
		{ name: 'Прибиральниця', text: text },
		{ name: 'Фасувальник', text: text },

		// { name: 'Керуючий Магазину', text: text }, 
		// { name: 'Комірник', text: text }, 
		// { name: 'Охорона', text: text }, 
		// { name: 'Комплектовщик', text: text }, 
		// { name: 'Грузчик', text: text }, 
		// { name: 'Піцейола', text: text }, 
		// { name: 'Бригадир', text: text }, 
	] }
];

const Vacancy = () => {

	const [city, setCity] = useState(undefined);
	const [vacancy, setVacancy] = useState(undefined);
	const [vacancyDet, setVacancyDet] = useState(undefined);
	const [desc, setDesc] = useState(undefined);
	const location = useLocation();
	const [stores, setStores] = useState([]);
	const [vacancyList, setVacancyList] = useState([]);
	const [group, setGroup] = useState(undefined);

	useEffect(() => {
		fetch(`${BASE_URL}/store`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
		.then(res => res.json())
		.then(res => setStores(res.filter(x => x.active)));

	}, []);

	useEffect(() => {
		if (city) {
			fetch(`${BASE_URL}/store/vacancy/show?store=${city._id}`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => res.json())
			.then(res => setVacancyList(res));
		}
	}, [city]);

	useEffect(() => {
		if (location.pathname === '/vacancy') {
			setCity(undefined); setVacancy(undefined); setVacancyDet(undefined); setDesc(undefined);
		}
	}, [location]);

	let brcr = [{label: 'Головна', link: ''}, { label: 'Вакансії', link: '/vacancy', callback: () => { setCity(undefined); setVacancy(undefined); setVacancyDet(undefined); setDesc(undefined); } }];
	if (city) brcr.push({ label: city.name, link: '/vacancy/' + city.name, callback: () => { setVacancy(undefined); setVacancyDet(undefined); setDesc(undefined); } });
	if (vacancy) brcr.push({ label: vacancy, link: '/vacancy/' + city.name, callback: () => { setVacancyDet(undefined); setDesc(undefined); } });
	if (vacancyDet) brcr.push({ label: vacancyDet, link: '/vacancy/' + city.name });

	const lst = vacancyList ? [...new Set(vacancyList.map(x => x.vacancy.group ? x.vacancy.group : x.vacancy.name))] : [];
	return (
		<Main>
			<Breadcrumb items={brcr} />
			{!city &&
				<div className="cities">
					{stores.map(v => (
						<Option label={v.name} imagePath={v.media ? v.media.path : undefined} link={'vacancy/' + v.name} key={v.name} mayResize color="green" onClick={() => { setCity(v); } } />
						// <Link to={'vacancy/' + v.name}>
						// 	<div className="city-container" key={v.name}>
						// 		<div className="city" onClick={() => { setCity(v); } }>
						// 			{(v.media && v.media.path) &&
						// 				<div className="store-image" style={{ backgroundImage: `url(${BASE_URL}/images/${v.media.path})` }}></div>
						// 			}
						// 			<div className="city-name">
						// 				<div className="city-name-label">
						// 					{v.name}
						// 				</div>
						// 			</div>
						// 		</div>
						// 	</div>
						// </Link>
					))}
				</div>
			}
			{(city && !vacancy) &&
				<div className="vacancies">
					{lst.map(v => (
						<div className="vacancy" onClick={() => { setVacancy(v); const vac = vacancyList.find(x => x.vacancy.name === v); if (vac) setDesc(vac.vacancy.desc || null); else setGroup(v); } }>
							{v}
						</div>
					))}
				</div>
			}
			{(vacancy && group && desc === undefined) &&
				<div className="subvacancies">
					{vacancyList.filter(x => x.vacancy.group === group).map(v => (
						<div className="subvacancy" onClick={() => { setVacancyDet(v.vacancy.name); setDesc(v.vacancy.desc || null); }}>
							{v.vacancy.name}
						</div>
					))}
				</div>
			}
			{desc !== undefined && 
				<div className="vacancy-description">
					<div className="vacancy-title">
						{vacancyDet ? vacancyDet : vacancy}
					</div>
					<div className="vacancy-details">
						{(desc !== null && desc !== undefined) &&
							<div class="vacacy-text">
								<div dangerouslySetInnerHTML={{ __html: desc }}></div>
							</div>
						}
						{desc === null &&
							<div dangerouslySetInnerHTML={{ __html: text }}></div>
						}
					</div>
				</div>
			}
		</Main>
	);
};

export default Vacancy;
