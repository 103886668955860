import React, { useState, useEffect } from "react";
import Main from "./main";
import "./conditions.scss";
import Breadcrumb from "../components/breadcrumb";
import { BASE_URL, FB_URL, TELEGRAM, VIBER } from '../config/config';


const Conditions = (props) => {

    const [content, setContent] = useState('');
	const [contentMobile, setContentMobile] = useState('');

	useEffect(() => {
		fetch(`${BASE_URL}/page/delivery`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => res.json())
			.then(res => setContent(res));
		fetch(`${BASE_URL}/page/delivery_mob`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => res.json())
			.then(res => setContentMobile(res));
	}, [])

	return (
		<Main>
			<Breadcrumb items={[{label: 'Головна', link: ''}, { label: 'Способи оплати та доставки', link: 'conditions'}]} />
			{content &&
				<>
					<div className="we-content-desktop" dangerouslySetInnerHTML={{__html: content }}></div>
					<div className="we-content-mobile" dangerouslySetInnerHTML={{__html: contentMobile }}></div>
				</>
			}
            {!content &&
                <div className="conditions">
                    <div className="header1">СПОСОБИ ДОСТАВКИ</div>
                    <ul>
                        <li>Самовивіз з магазинів Лафар</li>
                        <li>Доставка нашим кур'єром на вказану адресу, вартість доставки згідно з тарифом</li>
                    </ul>

                    <div className="header1">УМОВИ ДОСТАВКИ</div>
                    <div className="text m2">Послуги з доставки здійснюються з 8:30 до 19:30. Оплату за замовлення можливо</div>
                    <div className="text m2">здійснювати онлайн на сайті, готівковим чи безготівковим способом при отриманні.</div>
                    <div className="text m2">Мінімальна сума замовлення - 150 грн. Доставка у будь-яку точку міста від 30 хв.,</div>
                    <div className="text m2">вартість доставки - 49 грн.</div>
                    <div className="text m2">ПРИ ЗАМОВЛЕННІ ДОСТАВКИ - БУРГЕР (Бургер зі свининою класичний) У ПОДАРУНОК!</div>


                    <div className="header1">СПОСОБИ ОПЛАТИ:</div>
                    <div className="header2 m1">Оплата готівкою при отриманні товару</div>
                    <div className="text m2">Оплата готівкою при отриманні товару можлива в Лафара маркетах при видачі товару</div>
                    <div className="text m2">Оплата здійснюється виключно у національній валюті.</div>
                    <div className="text m2">На підтвердження оплати ми видаємо Вам товарний чек.</div>
                    <div className="header2 m1">Оплата банківською картою при отриманні товару</div>
                    <div className="text m2">Оплата банківською картою при отриманні товару можлива в місцях видачі товару, а саме на касі в Лафар маркеті</div>
                    <div className="text m2">Оплата здійснюється виключно у національній валюті.</div>
                    <div className="text m2">На підтвердження оплати ми видаємо Вам товарний і банківський чек.</div>
                    <div className="header2 m1">Оплата платіжною картою онлайн</div>
                    <div className="text m2">
                        Оплата за допомогою карт здійснюється наступним способом:
                        Під час оформлення замовлення на сайті, Вам буде запропоновано зробити вибір способу оплати.
                        У графі «способи оплати» вам потрібно вибрати «картою на сайті». Після цього Ви будете переадресовані
                        на сторінку системи безпечних платежів банку, де Вам необхідно буде підтвердити оплату.
                        Будь ласка, зверніть увагу, отримати товар, оплачений платіжною карткою, може тільки той клієнт,
                        чиї ПІБ були вказані на сайті під час оформлення заявки, тому при отриманні замовлення обов'язково
                        потрібно мати при собі паспорт або інший документ, що засвідчує особу
                    </div>
                    {/* <div className="header2 m1">Оплата безготівка</div>
                    <div className="text m2">
                        За для оплати товару по безготівковому рахунку в'яжіться будь ласка з представниками центрального офісу<br/>
                        по тел. 0 800 600 810 або залиште свій номер співробітникам у магазині<br/>
                        1. Дочекайтеся дзвінка оператора.<br/>
                        2. Підтвердіть замовлення.<br/>
                        3. На вказану електронну адресу вишліть вказані документи:<br/>
                        •свідоцтво про державну реєстрацію або виписку з єдиного державного реєстру;<br/>
                        •свідоцтво платника ПДВ (для організацій, оформлених в 01.01.2014г.)<br/>
                        •дійсний телефон бухгалтерії і телефон для зв'язку після оплати;<br/>
                        •адреса магазину, в якому буде зручно отримати товар;<br/>
                        •код і кількість товару.<br/>
                        4. Вкажіть правильну e-mail для відправки рахунку.<br/>
                        5. Оператор відправить рахунок-фактуру на вказаний в замовленні e-mail.<br/>
                        6. Після надходження коштів на рахунок, вам буде надіслано листа на електронну адресу<br/>
                        з підтвердженням отримання оплати і датою готовності товару в магазині.<br/>
                        7. Для отримання товару в магазині візьміть з собою паспорт та довіреність із зазначенням кількості одержуваного товару.<br/>
                        Якщо отримує власник підприємства, то досить паспорт і печатку підприємства.<br/>
                    </div>    */}
                    <div className="header1">ПОВЕРНЕННЯ ТОВАРУ</div>
                    <div className="text m1">
                        Обмін / повернення товару здійснюється протягом 14 днів при пред'явленням відповідного чека безпосередньо
                        в тому магазині, в якому була здійснена покупка даного товару
                    </div>
                    <div className="header1">ПОВЕРНЕННЯ КОШТІВ НА ПЛАТІЖНУ КАРТКУ</div>
                    <div className="text m1">
                        Здійснюється протягом п'яти банківських днів з моменту отримання заявки про повернення.
                        Повернення придбаних товарів здійснюється у випадках і згідно умов, регламентованих &nbsp;
                        <a href="http://zakon.rada.gov.ua/cgi-bin/laws/main.cgi?nreg=1023-12">«ЗАКОНОМ УКРАЇНИ ПРО ЗАХИСТ ПРАВ СПОЖИВАЧІВ».</a>&nbsp;
                        У разі повернення товару згідно Закону Вам необхідно звернутися в місце продажу, де саме проводилася покупка даного товару з чеком.
                    </div>
                    {/* <div className="header1">КОНФІДЕНЦІЙНІСТЬ</div>
                    <div className="text m1">
                        Сторони вживають на себе зобов'язання не розголошувати отримані в ході виконання Договору відомості, що є конфіденційними для кожної із Сторін. Під конфіденційною інформацією в Договорі розуміються не є загальнодоступними, розголошення яких може призвести до виникнення збитків (шкоди) та / або вплинути на ділову репутацію однієї із Сторін.<br />
                        Сторони зобов'язуються не розголошувати зазначену в п. 8.1. цього Договору інформацію третім особам, або попередньо узгодженої між Сторонами з метою виконання Договору, або яка є загальнодоступною.<br />
                        Інформація, зазначена в п. 8.1. цього Договору, може бути також видана в порядку, встановленому законодавством Республіки Казахстан.<br />
                        У випадку припинення дії Договору, Сторони зобов'язуються не розголошувати і не використовувати в своїх інтересах і / або інтересах третіх осіб інформацію, зазначену в п. 8.1. Договору, протягом 5 (п'яти) років з моменту припинення дії Договору.<br />
                    </div> */}

                    {/* <div className="lbox">
                        <div className="lbox-line"></div>
                        <div className="lbox-text">
                            Задля  попередження шахрайських операцій з використанням реквізитів платіжних карток, всі операції, вяких використовуються параметри картки, виконуються на захищеній сторінці процесингового центру банка, безпека якого підтверджена сертифікатом PCI DSS
                        </div>
                    </div>
                    <div className="lbox">
                        <div className="lbox-line"></div>
                        <div className="lbox-text">
                            З метою забезпечення найбільш сучасних засобів безпеки онлайн платежів ми підтримуємо послуги забезпечення Visa Secure и MasterCard SecureCode .Натисніть на відповідний логотип для отримання додаткової інформації.
                        </div>
                    </div> */}
                    <div className="final">З питань роботи інтернет магазину звертайтесь за номером інформаційної підтримки 0800 600 810 (з 08:00 до 18:00)</div>
                    <div className="logos">
                        <a href="https://www.visa.com.ua/uk_UA/pay-with-visa/featured-technologies/visa-secure.html"><div className="visa"></div></a>
                        <a className="link" href="https://www.pumb.ua/ru"><div className="pumb-container">
                            <div className="pumb"> </div>
                            <div>Працюємо<br/> для Вас</div>
                        </div></a>
                        <a href="https://www.mastercard.ua/uk-ua/personal/get-support/safety-and-security/securecode.html"><div className="mastercard"></div></a>
                    </div>
                </div>
            }
		</Main>
	);
};

export default Conditions;
