import React, { useEffect, useContext, useState } from "react";
import Main from "./main";
import { Link } from 'react-router-dom';
// import Banner1 from '../components/banners/banner1';
import "./we.scss";
import Breadcrumb from "../components/breadcrumb";
import { BASE_URL } from '../config/config';


const We = () => {

	const [content, setContent] = useState('');
	const [contentMobile, setContentMobile] = useState('');

	useEffect(() => {
		fetch(`${BASE_URL}/page/we`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => res.json())
			.then(res => setContent(res));
		fetch(`${BASE_URL}/page/we_mob`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => res.json())
			.then(res => setContentMobile(res));
	}, [])

	return (
		<Main>
			<Breadcrumb items={[{label: 'Головна', link: ''}, { label: 'Чому ми?', link: 'we'}]} />
			{content &&
				<>
					<div className="we-content-desktop" dangerouslySetInnerHTML={{__html: content }}></div>
					<div className="we-content-mobile" dangerouslySetInnerHTML={{__html: contentMobile }}></div>
				</>
			}
			{!content &&
				<>
					<div className='logo-container'>
						<div className='logo'></div>
					</div>
					<div className='we-text-subtitle'>ІНТЕГРУЄМО ЄВРОПУ ДО НАС, І НАС В ЄВРОПУ</div>
					<div className="we-row">
						<div className="we-image"></div>
						<div className="we-text">
							Вся планета охоплена пандемією COVID 19.<br />
							Вимоги до супермаркетів зросли. <br />
							Гідних маркетів - одиниці. <br />
							<br />
							Це спонукало нас до створення супермаркетів нового рівня.<br />
							LAFAR - представники торгової культури європейських супермаркетів. <br />
							Наша мета — стати популярним місцем покупок і престижним роботодавцем.<br />
							Європейський рівень життя починається з нас. <br />
							<br />
							Ми - це принади європейського життя вже зараз, недалеко від того місця, де ми регулярно проводимо час. <br />
							У нас є ряд переваг, таких як: величезний асортимент, приємна ціна, велика економія часу, <br />
							можливість ретельно ознайомитися з товаром, а в тих чи інших випадках скористатися послугами консультантів.
						</div>
					</div>
					<div className="we-mob1"></div>
					<div className="we-value">
						<div className="we-value-left">
							<div className="we-value-text">
								Якість і ціна – дві позиції, які Ви невтомно намагаєтесь знайти щодня в різних продуктах, марках та товарах. Щоразу натикаючись на розбіжність, розчаровуєшся та думаєш, що не знайдеш нічого ідеального?<br />
								Стій-стій, «LAFAR» - саме те, що ти так довго шукав! Недаремно нашим символом є зебра, два кольори якої так символічно розказують про нашу мету – поєднати якісні товари з доступними цінами.<br />
							</div>
						</div>
						<div className="we-value-image"></div>
					</div>
					<div className="we-value-bottom we-hide-big">
						Якість і ціна – дві позиції, які Ви невтомно намагаєтесь знайти щодня в різних продуктах, марках та товарах. Щоразу натикаючись на розбіжність, розчаровуєшся та думаєш, що не знайдеш нічого ідеального?
						Стій-стій, «LAFAR» - саме те, що ти так довго шукав! Недаремно нашим символом є зебра, два кольори якої так символічно розказують про нашу мету – поєднати якісні товари з доступними цінами.
						<br />
						<br />
						<br />
					</div>
					<div className="we-value-bottom">
						«LAFAR» - це супермаркет, в якому на кожній поличці є те, що тобі ну дуже потрібно.
						<br />
						Вийти від нас з пустими кишенями не вийде, а от з купою пакетів і повним гаманцем – запросто!
					</div>
					<div className="we-mob2"></div>
				</>
			}
		</Main>
	);
};

export default We;
