import React, { useState, useEffect } from 'react';
import AdminContainer from './admin_container';
import Select from 'react-select';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import { BASE_URL } from '../../config/config';
import './admin.scss';

const Home = () => {

	const [media, setMedia] = useState([]);
	const [list, setList] = useState([]);
	const [chosenMedia, setChosenMedia] = useState(undefined);
	const [chosenMediaCaption, setChosenMediaCaption] = useState(undefined);
	const [link, setLink] = useState('');

	const add = (id, idCaption) => {
		fetch(`${BASE_URL}/home`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id: id, idCaption: idCaption, link: link }) })
		.then(res => getLinks())
	}

	const del = (id) => {
		fetch(`${BASE_URL}/home`, { method: 'DELETE', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id: id }) })
		.then(res => getLinks())
	}

	const changePos = (id1, id2) => {
		fetch(`${BASE_URL}/home/changeOrder`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id1: id1, id2: id2 }) })
		.then(res => getLinks())
	}

	const setActive = (id, value) => {
		fetch(`${BASE_URL}/home/active`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id: id, value: value }) })
		.then(res => getLinks())
	}

	const getLinks = () => {
		fetch(`${BASE_URL}/home`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
		.then(res => res.json())
		.then(res => setList(res));
	}
	useEffect(() => { getLinks();}, [])

	useEffect(() => {
		fetch(`${BASE_URL}/media`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
		.then(res => res.json())
		.then(res => { 
			const listIds = list.map(x => x.media.id);
			setMedia(res.filter(x => !listIds.includes(x.id)).map(x => ({ value: x.id, label: x.name })))
		});
	}, [list]);

	return (
		<AdminContainer active="home">
			<div className="home">
				<div className="home-add">
					<Select placeholder="Картинка" className="home-select" options={media} value={chosenMedia} onChange={(v) => setChosenMedia(v)} />
					<Select placeholder="Текст" className="home-select" options={media} value={chosenMediaCaption} onChange={(v) => setChosenMediaCaption(v)} />
					<input type="text" className="home-input" value={link} onChange={(e) => setLink(e.target.value)}></input>
					<div className="btn btn-alone" onClick={chosenMedia ? () => add(chosenMedia.value, chosenMediaCaption?.value) : undefined}>Добавить баннер</div>
				</div>
				<div className="banners-table-container">
					<table className="banners-table">
						<thead>
							<tr>
								<th className="cactive">Активный</th>
								<th className="cview">Вид</th>
								<th className="cname">Название</th>
								<th className="cdel">Удалить</th>
								<th className="corder">Порядок</th>
							</tr>
						</thead>
						<tbody>
							{list.map((el, i) => (
								<tr key={el.id}>
									<td><input type="checkbox" checked={el.active} onChange={() => setActive(el.id, !el.active)}></input></td>
									<td>
										{(el.media && el.media.path) &&
											<img src={BASE_URL + '/images/' + el.media.path}></img>
										}
									</td>
									<td>{el.media ? el.media.name :''}</td>
									<td className="pointer" onClick={() => del(el.id)}>X</td>
									<td><FiArrowDown className="pointer"  onClick={list.length > i+1 ? () => changePos(el.id, list[i+1].id) : undefined} /> &nbsp;&nbsp;&nbsp;<FiArrowUp className="pointer" onClick={i > 0 ? () => changePos(el.id, list[i-1].id): undefined} /></td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</AdminContainer>
	);
}

export default Home;