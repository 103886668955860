import React from 'react';
import './banner.scss';
import { BASE_URL } from '../../config/config';

const Banner = (props) => {

	return (
		<div className={'banner ' + props.image} style={props.url ? { backgroundImage: `url(${BASE_URL}/images/${props.url})`} : {}}>
		</div>
	);
}

export default Banner;