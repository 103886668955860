import React, { useState, useEffect } from 'react';
import AdminContainer from './admin_container';
import { BASE_URL } from '../../config/config';
import './admin.scss';

const Media = () => {

	const [list, setList] = useState([]);
	const defOb = { id: undefined, name: '', desc: '', file: undefined, new: true };
	const [ob, setOb] = useState(defOb);
	const [filter, setFilter] = useState('');

	const addMedia = async () => {
		const fname = await doUpload(ob.file);
		console.log(fname);
		fetch(`${BASE_URL}/media`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ name: ob.name, desc: ob.desc, file: fname.file }) })
		.then((res) => {
			if (res.status === 200) {
				getMedia();
				setOb(defOb);
			}
		});
	
	}
	const delMedia = () => {
		fetch(`${BASE_URL}/media`, { method: 'DELETE', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id: ob.id }) })
		.then(res => res.json())
		.then(res => {
			setList(list.filter(x => x.id !== res).map(x => ({...x})));
			setOb(defOb);
		})
	}

	const updMedia = async () => {
		let flpart = {};
		if (ob.file) {
			const fname = await doUpload(ob.file);
			flpart = { file: fname.file };
		}
		fetch(`${BASE_URL}/media`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(Object.assign({}, { id: ob.id, name: ob.name, desc: ob.desc }, flpart)) })
		.then(res => res.json())
		.then(res => {
			getMedia();
			setOb(defOb);
		})
	}

	const getMedia = () => {
		fetch(`${BASE_URL}/media`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
		.then((res) => res.json())
		.then((res) => {
			console.log(res);
			setList(res);
		})
	}

	const doUpload = async (file) => {
		const fdata = new FormData();
		fdata.append('file', file);
		fdata.append('name', file.name);
	
		const res = await fetch(BASE_URL + '/upload', { method: 'POST', body: fdata })
		const resj = await res.json();
		return resj;
	}
	

	useEffect(() => {
		getMedia();
	}, []);

	const validFields = ob.name && ob.file && !ob.id;
	return (
		<AdminContainer active="media">
			<div className="media">
				<div className="media-row">
					<div className="media-add-col">
						<div className="media-add-row">
							<div className="media-add-row-name">
								<div>Название&nbsp;</div>
								<input type="text" value={ob.name} onChange={(e) => setOb({...ob, name: e.target.value})}></input>
							</div>
							<div className="upload">
								<input type="file" accept="image/*" onChange={(evt) => {
									setOb({...ob, file: evt.target.files[0]})
								}} /> 
							</div>
							<div className="media-add-row-buttons">
								<div className={'btn ' + (validFields ? '' : 'btn-disabled')} onClick={validFields ? () => addMedia() : undefined}>Добавить</div>
								<div className={'btn ' + (ob.id ? '' : 'btn-disabled')} onClick={ob.id ? () => delMedia() : undefined}>Удалить</div>
								<div className={'btn ' + (ob.id ? '' : 'btn-disabled')} onClick={ob.id ? () => updMedia() : undefined}>Обновить</div>
							</div>
						</div>
						<div className="media-desc">
							<div>Описание</div>
							<input type="text" value={ob.desc} onChange={(e) => setOb({...ob, desc: e.target.value})}></input>
						</div>
					</div>
					<div className="media-search">
						<input type="text" placeholder="Фильтр" value={filter} onChange={v => setFilter(v.target.value)}></input>
					</div>
				</div>
				<div className="media-table-container">
					<table className="media-table">
						<thead>
							<tr>
								<th className="cview">Вид</th>
								<th className="cname">Имя</th>
								<th className="cdesc">Описание</th>
							</tr>
						</thead>
						<tbody>
							{list.filter(v => !filter || v.name.toLowerCase().includes(filter.toLowerCase()) || v.desc.toLowerCase().includes(filter.toLowerCase())).map((r) => (
								<tr key={r.id} onClick={() => setOb({ id: r.id, name: r.name, desc: r.desc, file: r.file, new: false })} className={r.id === ob.id ? 'active' : ''}>
									<td>
										<img src={BASE_URL + '/images/' + r.path}></img>
									</td>
									<td>{r.name}</td>
									<td>{r.desc}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</AdminContainer>
	);
}

export default Media;