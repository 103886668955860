import React, {useState, useEffect} from "react";
import { BASE_URL } from '../config/config';
import Select, { components } from 'react-select';
import { choose } from "../reducers/reducer";
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

const customStyles = {
	menu: (provided) => ({
		...provided,
		zIndex: 100
	})
}

const ChooseStore = ({ store, theme, location, choose }) => {

	const storeId = store ? store.id : sessionStorage.getItem('store');

	const [cities, setCities] = useState([]);
	const [addresses, setAddresses] = useState([]);

	const [city, setCity] = useState();
	const [address, setAddress] = useState();

	const history = useHistory();
	const [promo, setPromo] = useState(false);


	useEffect(() => {
		getStores();
	}, [])

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		setPromo(!!params.get('promo'));
	}, [window.location])

	const getStores = () => {
		fetch(`${BASE_URL}/store`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
		.then(res => res.json())
		.then(res => {
            console.log('aaaa',res);
			setCities(res.filter(x => x.active && x.istore !== false).map(x => ({label: x.name, value: x.id }) ));
			setAddresses(res.filter(x => x.active && !x.istore !== false).map(x => ({label: x.address, value: x }) ));
		});
	}

	const enabled = city && address;
	return (
		<>
			<div className="store-container">
				<div className="store-title">Час не втрачай, <br />доставку замовляй!</div>
				<div className="store-container-wrapper">
					<div className="store-select-label">Місто:</div>
					<Select styles={customStyles} placeholder="" options={cities} value={city} onChange={(v) => setCity(v)} className={'store-select' + (theme === 'white' ? ' store-select-shadow' : '')} />
					<div className="store-select-label">Адреса магазина:</div>
					<Select styles={customStyles} placeholder="" options={addresses.filter(x => city && x.value.id === city.value)} value={addresses.find(v => store && v.value.id === storeId)} onChange={(v) => setAddress(v.value)} className={'store-select' + (theme === 'white' ? ' store-select-shadow' : '')} />
				</div>
				<div className={`store-button ${enabled ? '' : 'disabled'}`} onClick={enabled ? () => {choose(address); history.push(window.location.pathname + '?store=' + address.id + (promo ? '&promo=1' : ''))} : () => {}}>Далі</div>
				<Link to={'/conditions'} className="store-desc-label-link"><div className="store-desc-label">Способи оплати та доставки</div></Link>
			</div>
		</>
	);

}

const mapStateToProps = (state) => ({
	store: state.store,
	theme: state.theme
});

const mapDispatchToProps = (dispatch) => ({
	choose: (store) => dispatch(choose(store)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChooseStore);
