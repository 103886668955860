export const kops = (v, pres = true) => {
	const r = (Math.round(v * 100) - Math.round(Math.floor(v) * 100));
	return pres ? (r.toString().length === 1 ? `0${r}` : `${r}`) : r;
}

export const kop = (v) => {
	return v.toString().length === 1 ? `${v}0` : v;
}

const whatDecimalSeparator = () => {
    var n = 1.1;
    n = n.toString().substring(1, 2);
    return n;
}
export const rkop = (v) => {
	const sep = whatDecimalSeparator();
	const spl = v.toString().split(sep);
	return spl.length === 1 ? v + sep + '00' : (v + (spl[1].length === 1 ? '0' : ''));
}

export const carItems = (arr, start, length) => {
	const l = arr.legth;
	let res = [];
	if (start + length >= l) {
		res = arr.slice(start, l).concat(arr.slice(0, Math.min(l, length - (l - start))));
	} else {
		res = arr.slice(start, start + length);
	}
	return res;
}

export const price = (ob) => {
	return ob.pricep > 0 ? ob.pricep : ob.price;
}

export const priceOld = (ob) => {
	return ob.pricep > 0 ? ob.price : undefined;
}

export const phonepres = (n = '') => {
	return !n ? '' : `+${n.slice(0,2)} (${n.slice(2,5)}) ${n.slice(5,8)} ${n.slice(8,10)} ${n.slice(10,12)}`;
}

export const qtyPres = (v, isWeight) => { const s = ('' + Math.round(v * 10)); return (isWeight || Math.round(v * 10) - Math.round(v) * 10 > 0) ? (s.slice(0, -1) || '0') + '.' + s.slice(-1) + '00' : v };

export const isWeight = (art) => art.unit === 'кг';