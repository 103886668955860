import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import AdminContainer from './admin_container';
import { BASE_URL } from '../../config/config';
import './admin.scss';

const Promotion = () => {

	const [media, setMedia] = useState([]);
	const [list, setList] = useState([]);
	const [chosenMedia, setChosenMedia] = useState(undefined);
	const [store, setStore] = useState(true);

	const add = (id, store) => {
		fetch(`${BASE_URL}/promotion`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id: id, store: store }) })
		.then(res => getPromotions())
	}

	const setPromotionStore = (id, store) => {
		fetch(`${BASE_URL}/promotion/store`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id: id, store: store }) })
		.then(res => getPromotions())
	}

	const del = (id) => {
		fetch(`${BASE_URL}/promotion`, { method: 'DELETE', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id: id }) })
		.then(res => getPromotions())
	}

	const changePos = (id1, id2) => {
		fetch(`${BASE_URL}/promotion/changeOrder`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id1: id1, id2: id2 }) })
		.then(res => getPromotions())
	}

	const setActive = (id, value) => {
		fetch(`${BASE_URL}/promotion/active`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id: id, value: value }) })
		.then(res => getPromotions())
	}

	const getPromotions = () => {
		fetch(`${BASE_URL}/promotion`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
		.then(res => res.json())
		.then(res => setList(res));
	}
	useEffect(() => { getPromotions();}, [])
	useEffect(() => {
		fetch(`${BASE_URL}/media`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
		.then(res => res.json())
		.then(res => { 
			const listIds = list.map(x => x.media.id);
			setMedia(res.filter(x => !listIds.includes(x.id)).map(x => ({ value: x.id, label: x.name })))
		});
	}, [list]);

	const storeCafe = (store, setStore) => {
		return (
			<div className="store-cafe">
				<div className={'store-cafe-el ' + (store ? 'store-cafe-active' : '' ) } onClick={() => setStore(true)}>Магазин</div>
				<div className={'store-cafe-el ' + (!store ? 'store-cafe-active' : '' ) } onClick={() => setStore(false)}>Кафе</div>
			</div>
		);
	}

	return (
		<AdminContainer active="promotion">
			<div className="banners">
				<div className="banner-add">
					<Select className="banner-select" options={media} value={chosenMedia} onChange={(v) => setChosenMedia(v)} />
					{storeCafe(store, setStore)}
					<div className="btn btn-alone" onClick={chosenMedia ? () => add(chosenMedia.value, store) : undefined}>Добавить акцию</div>
				</div>
				<div className="banners-table-container">
					<table className="banners-table">
						<thead>
							<tr>
								<th className="cactive">Активный</th>
								<th className="cactive">Тип</th>
								<th className="cview">Вид</th>
								<th className="cname">Название</th>
								<th className="cdel">Удалить</th>
								<th className="corder">Порядок</th>
							</tr>
						</thead>
						<tbody>
							{list.map((el, i) => (
								<tr key={el.id}>
									<td><input type="checkbox" checked={el.active} onChange={() => setActive(el.id, !el.active)}></input></td>
									<td>{storeCafe(el.store, () => setPromotionStore(el.id, !el.store))}</td>
									<td>
										{(el.media && el.media.path) &&
											<img src={BASE_URL + '/images/' + el.media.path}></img>
										}
									</td>
									<td>{el.media ? el.media.name :''}</td>
									<td className="pointer" onClick={() => del(el.id)}>X</td>
									<td><FiArrowDown className="pointer"  onClick={list.length > i+1 ? () => changePos(el.id, list[i+1].id) : undefined} /> &nbsp;&nbsp;&nbsp;<FiArrowUp className="pointer" onClick={i > 0 ? () => changePos(el.id, list[i-1].id): undefined} /></td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</AdminContainer>
	);
}

export default Promotion;