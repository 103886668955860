
const BASE_URL = process.env.NODE_ENV === "production" ? 'https://lafar.com.ua/back' : 'http://localhost:4001';
const FB_URL = 'https://www.facebook.com/LaFar-%D0%BC%D0%B0%D1%80%D0%BA%D0%B5%D1%82-100117261874958';
const LoyConnect = 'NjQ1N2U0OTMtYjIzZi00ZWIxLTljY2QtNzJiMDk0OGNkOWM2Og==';

const TELEGRAM = 'https://t.me/lafarua';
const VIBER = 'https://invite.viber.com/?g2=AQB9YhiVZH61z0zGe%2FfddYtuCd5h2NXaWr52xG5fRW7YDJMhtIQKYqQpqKgooNap';

const getMobileLink = () =>  {
	var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
		// Windows Phone must come first because its UA also contains "Android"
	  if (/windows phone/i.test(userAgent)) {
		  return "https://play.google.com/store/apps/details?id=com.abmloyalty.lafar";
	  }
  
	  if (/android/i.test(userAgent)) {
		  return "https://play.google.com/store/apps/details?id=com.abmloyalty.lafar";
	  }
  
	  // iOS detection from: http://stackoverflow.com/a/9039885/177710
	  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
		  return "https://apps.apple.com/ua/app/lafar/id1531497263?l=ru";
	  }
  
	  return "https://play.google.com/store/apps/details?id=com.abmloyalty.lafar";
}
  
export { BASE_URL, FB_URL, getMobileLink, LoyConnect, TELEGRAM, VIBER };