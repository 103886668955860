import React from 'react';
import './products.scss';
import { BASE_URL } from '../../config/config';

const Product = (props) => {

	return (
		<>
			{props.img &&
				<div className={'product'} style={{ backgroundImage: `url(${BASE_URL}/images/${props.img})` }}></div>
			}
			{props.path &&
				<div className={`product ${props.path}`}></div>
			}
		</>
	);
}

export default Product;