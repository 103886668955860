import React, { useState, useEffect } from "react";
import { BASE_URL } from '../config/config';
import Main from "./main";
import Breadcrumb from "../components/breadcrumb";
import Construction from '../components/construction';
import './manufacture.scss';
import Carousel2 from "./carousel2";
import Product from '../components/manufacture/product';
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Manufacture = () => {

	const types = ['smoked', 'bakery'];

	const [type, setType] = useState(types[0]);
	const [media, setMedia] = useState(undefined);
	const [back, setBack] = useState(false);
	const [pos, setPos] = useState(0);


	const toShop = () => (
		<Link to="/store/5fc74851daa7a503e03ea0e2">
			<div className="manufacture-shop">ДО МАГАЗИНУ</div>
		</Link>
	)
	
	const text = (type) => {
		const title = type === 'smoked' ? 'ВЛАСНЕ КОПЧЕННЯ ТА СМАЖЕННЯ' : 'ВЛАСНА ПЕКАРНЯ';
		const txt = type === 'smoked' ? `
		<div>
		Власне копчення та смаження - Власна коптильня в маркетах Lafar,  що може бути краще? Коптимо прямо тут на натуральній вільсі!
		<br />
		Все ще треба до Вашого столу в нас вже є! Поспішайте додому, а вечерю ще готувати? Стійте!
		<br />
		Візьміть ароматну курочку з хрусткою скоринкою, рибку холодного чи гарячого копчення, будь-який гарнір і смачна вечеря вже на вашому столі!
		</div>
		` : `
		<div>
		Наша власна пекарня створена за для того, щоб щодня пекти найсмачніші, найсвіжіші булочки, хліб, піцци, та ще багато різних смаколиків для Вас!
		<br />
		Наші пекарі вкладають частинку любові та гарного настрою до кожною випічки! Готуємо тільки з найкращих продуктів та одразу викладаемо на полиці.
		<br />
		Ассортимент та ціни Вас приємно здивують! Гаряча віпічка в Lafar маркеті чекає на Вас!
		</div>
		`;
		 
		return (
			<div className="manufacture-desc">
				<div className="manufacture-desc-title">
					{title}
				</div>
				<div className="manufacture-desc-text" dangerouslySetInnerHTML={{ __html: txt }}></div>
				{toShop()}
			</div>
		)
	}

	useEffect(() => {
		fetch(`${BASE_URL}/manufacture/show`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => res.json())
			.then(res => {
				setMedia(res || []);
				setBack(true);
			})
	}, [])


	const SMOKED = [<Product path="product1" />, <Product path="product2" />, <Product path="product3" />, <Product path="product4" />];
	const BAKERY = [<Product path="product5" />, <Product path="product6" />, <Product path="product7" />, <Product path="product8" />];

	// const productsSmoked = [<Product img="manufacture/product1.png" />, <Product name="manufacture/product2.png" />, <Product name="manufacture/product3.png" />, <Product name="manufacture/product4.png" />];
	const productsSmoked = media === undefined ? [] : media.filter(x => x.type === types[0]).map(x => (<Product img={x.path} />));
	const productsBakery = media === undefined ? [] : media.filter(x => x.type === types[1]).map(x => (<Product img={x.path} />));

	const curList = type === 'smoked' ? (productsSmoked.length === 0 ? SMOKED : productsSmoked) : (productsBakery.length === 0 ? BAKERY : productsBakery);
	const curListPart = curList.length >= pos + 4 ? curList.slice(pos, pos + 4) : curList.slice(pos).concat(curList.slice(0, 4 - (curList.length - pos)));

	return (
		<Main>
			<Breadcrumb items={[{label: 'Головна', link: ''}, { label: 'Власне виробництво', link: 'manufacture'}]} />
			<div className="manufacture">
				<div className="promotions-type">
					<div className={'promotion-type' + (type === 'smoked' ? ' chosen' : '')} onClick={() => setType('smoked')}>КОПЧЕНІ ВИРОБИ</div>
					<div className={'promotion-type' + (type === 'bakery' ? ' chosen' : '')} onClick={() => setType('bakery')}>ВЛАСНА ПЕКАРНЯ</div>
				</div>
				<div className="carousel">
					<Carousel2 banners={curListPart} left={() => setPos(pos === 0 ? curList.length -1  : pos - 1)} right={() => setPos(pos === curList.length - 1 ? 0 : pos + 1)} />
				</div>
				{text(type)}
				
				{/* <div className="manufacture-title">ЩО МИ РОБИМО:</div>
				<div className="manufacture-image"></div>
				<div className="carousel">
					<Carousel2 banners={[<Product name="product1" />, <Product name="product2" />, <Product name="product3" />, <Product name="product4" />]} />
				</div>
				<div className="manufacture-details"></div> */}
			</div>
		</Main>
	);
};

export default Manufacture;
