import { createSlice } from "@reduxjs/toolkit";
import basket from "../containers/basket";

const MainReducer = createSlice({
	name: 'login',
	initialState: {
		isLoggedIn: false,
		login: undefined,
		password: undefined,
		store: undefined,
		basket: [],
		fav: [],
		theme: 'black'
	},
	reducers: {
		signin: (state, action) => {
			if (action.payload.login === 'admin' && action.payload.password === '159357') {
				state.login = action.payload.login;
				state.password = action.payload.password;
				state.isLoggedIn = true;
			}
		},
		choose: (state, action) => {
			if (action.payload && action.payload.id) {
				state.store = action.payload;
				sessionStorage.setItem('store', action.payload.id);
			}
		},
		basketAdd: (state, action) => {
			const el = state.basket.find(x => x.article.id === action.payload.article.id);
			let res;
			if (el) res = state.basket.map((x) => x === el ? {...x, qty: Math.max(0, x.qty + action.payload.qty)} : x);
			else {
				res = state.basket.slice();
				res.push({ article: action.payload.article, qty: Math.max(action.payload.qty, 0) });
			}
			state.basket = res.filter(x => x.qty > 0);
			localStorage.setItem('basket', JSON.stringify(state.basket.map(x => ({ id: x.article.id, qty: x.qty }))));
		},
		basketClear: (state) => {
			state.basket = [];
			localStorage.setItem('basket', JSON.stringify([]));
		},
		basketSet: (state, action) => {
			state.basket = action.payload;
		},
		basketDel: (state, action) => {
			state.basket = state.basket.filter(x => x.article.id !== action.payload);
			localStorage.setItem('basket', JSON.stringify(state.basket.map(x => ({ id: x.article.id, qty: x.qty }))));
		},
		favToggle: (state, action) => {
			if (state.fav.map(x => x.id).includes(action.payload.id)) state.fav = state.fav.filter(x => x.id !== action.payload.id)
			else state.fav.push(action.payload);
		},
		favClear: (state, action) => {
			state.fav = [];
		},
		toggleTheme: (state, action) => {
			if (action.payload) {
				state.theme = action.payload;
			} else {
				state.theme = state.theme === 'black' ? 'white' : 'black';
			}
			localStorage.setItem('theme', state.theme);
			document.documentElement.style.setProperty('--c', state.theme === 'black' ? '#FFFFFF' : '#000000');
			document.documentElement.style.setProperty('--bg', state.theme === 'black' ? '#000000' : '#FFFFFF');
			document.documentElement.style.setProperty('--inv', state.theme === 'black' ? 'invert(0)' : 'invert(1)');
			document.documentElement.style.setProperty('--inv1', state.theme === 'black' ? 'invert(1)' : 'invert(0)');
			document.documentElement.style.setProperty('--bggryel', state.theme === 'black' ? 'linear-gradient(0deg, #AFC04E 14.62%, #D5E670 45.92%, #DDEA73 87.42%)' : 'linear-gradient(0deg, #98CE8F 14.62%, #B1E7A7 45.92%, #B1E7A7 87.42%)');
			document.documentElement.style.setProperty('--fgryel', state.theme === 'black' ? '#9EDA93' : '#F1F087');
			document.documentElement.style.setProperty('--fgryeli', state.theme === 'white' ? '#F1F087' : '#DDEA73');
			document.documentElement.style.setProperty('--ygr', state.theme === 'black' ? '#F1F087' : '#9EDA93');
			document.documentElement.style.setProperty('--yeltob', state.theme === 'black' ? '#F1F087' : '#000000');
			document.documentElement.style.setProperty('--wtog', state.theme === 'black' ? '#FFFFFF' : '#9EDA93');
			document.documentElement.style.setProperty('--wtob', state.theme === 'black' ? '#FFFFFF' : '#000000');
			document.documentElement.style.setProperty('--wtogrey', state.theme === 'black' ? '#FFFFFF' : '#F5F5F5');
			document.documentElement.style.setProperty('--gtor', state.theme === 'black' ? '#9EDA93' : '#FF154D');
			document.documentElement.style.setProperty('--yeldtol', state.theme === 'black' ? '#DDEA73' : '#F1F087');
			document.documentElement.style.setProperty('--yel1tob', state.theme === 'black' ? '#DDEA73' : '#000000');
			document.documentElement.style.setProperty('--grgr', state.theme === 'black' ? '#9EDA93' : '#B1E7A7');
			document.documentElement.style.setProperty('--shadow', state.theme === 'black' ? 'none' : 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)');

			document.documentElement.style.setProperty('--bgr', state.theme === 'black' ? '#549b7a' : '#9eda93');
			document.documentElement.style.setProperty('--bgr2', state.theme === 'black' ? '#359d78' : '#9eda93');
			document.documentElement.style.setProperty('--bgr3', state.theme === 'black' ? 'rgb(109, 183, 141)' : '#6db78d');
			document.documentElement.style.setProperty('--by', state.theme === 'black' ? '#d8e872' : '#f1f087');
			document.documentElement.style.setProperty('--wtog', state.theme === 'black' ? '#FFFFFF' : '#359d78');
			document.documentElement.style.setProperty('--gtow', state.theme === 'black' ? '#359d78' : '#FFFFFF');
			document.documentElement.style.setProperty('--wtolg', state.theme === 'black' ? '#FFFFFF' : '#d9eb6c');
			document.documentElement.style.setProperty('--yg', state.theme === 'black' ? '#d8e872' : '#359d78');
			document.documentElement.style.setProperty('--gtolg', state.theme === 'black' ? '#359d78' : '#d9eb6c');

		}
	},
});

export const { signin, choose, basketAdd, basketClear, favToggle, favClear, basketSet, basketDel, toggleTheme } = MainReducer.actions;
export default MainReducer.reducer;
