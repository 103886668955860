import React from "react";
import "./App.scss";
import AppContainer from "./client/containers/appContainer";
import { Provider } from 'react-redux';
import store from './store';

function App(props) {
	console.log(props);
	return (
		<Provider store={store}>
			<div className="App">
			<AppContainer />
			</div>
		</Provider>
	);
}

export default App;
