import React, { useState, useEffect, useRef } from "react";
import { AiFillCaretRight, AiFillCaretLeft } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import './carousel.scss';

function useInterval(callback, delay) {
	const savedCallback = useRef();

	// Remember the latest function.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		function tick() {
			savedCallback.current();
		}
		if (delay !== null) {
			let id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
}

const Carousel = (props) => {
	const [num, setNum] = useState(0);

	const updNum = (inc) => {
		let newNum = (num + inc) % props.banners.length;
		if (newNum < 0) newNum = props.banners.length + newNum;
		setNum(newNum);
	}

	useInterval(() => {
		updNum(1);
	}, 6000 + num);

	// return <div>{props.banners[num]}</div>;
	return (
		<div className="carousel-container">
			<div className="carousel-left" onClick={() => updNum(-1)}><AiFillCaretLeft /></div>
			<Link to={'/promotions'}>
				{props.banners.map((x, i) => (
					<div key={i} className={`img ${i === num ? 'opaque' : ''}`}>
						{x}
					</div>
				))}
			</Link>
			<div className="carousel-right" onClick={() => updNum(1)}><AiFillCaretRight /></div>
		</div>
	);
};

export default Carousel;
