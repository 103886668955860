import React, { useEffect, useState } from "react";
import Main from "./main";
import Breadcrumb from "../components/breadcrumb";
import Construction from '../components/construction';
import './basket.scss';
import { BASE_URL, LoyConnect } from '../config/config';
import Banner from "../components/banners/banner";
import Option from "../components/option";
import { connect } from 'react-redux';
import { FaPlusCircle, FaMinusCircle, FaRegTrashAlt, FaChevronRight, FaChevronDown, FaDivide } from 'react-icons/fa';
import { basketDel, basketAdd, choose, basketClear } from "../reducers/reducer";
import { useHistory } from 'react-router-dom';
import DatePicker, { registerLocale } from "react-datepicker";
import el from "date-fns/locale/uk";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Select from 'react-select';
import { kop, kops, rkop, carItems, price, phonepres, qtyPres, isWeight } from '../helpers/num';
import ArtCarousel from '../components/artcarousel';
import moment from 'moment';
import ChooseStore from './chooseStore';
import { Link } from "react-router-dom/cjs/react-router-dom.min";

registerLocale("el", el);

const DELIVERY = 'delivery';
const PICKUP = 'pickup';
const THRESHOLD = 150;

const Basket = (props) => {

	const mobile = window.screen.width <= 400;

	const [dt, setDt] = useState(new Date().getTime());
	const [tm, setTm] = useState(new Date().getTime() + 3600 * 1000 / 60 * 40);
	const [phone, setPhone] = useState(undefined);
	const [stores, setStores] = useState([]);
	const [card, setCard] = useState('');
	const [fio, setFio] = useState('');
	const [odd, setOdd] = useState(0);
	const [comment, setComment] = useState('');
	const [showSuccess, setShowSuccess] = useState(undefined);
	const [showConfirmmation, setShowConfirmmation] = useState(false);
	const [showCashBank, setShowCashBank] = useState(false);
	const [errors, setErrors] = useState({});
	const [packOpen, setPackOpen] = useState(false);
	const [packs, setPacks] = useState({ small: 0, middle: 0, big: 0 });
	const [contact, setContact] = useState('phone');
	const [artchange, setartchange] = useState(false);
	const [oferta, setOferta] = useState(false);
	const [paymeth, setPaymeth] = useState('cash');
	const [delmeth, setDelmeth] = useState(PICKUP);

	const [pickCity, setPickCity] = useState();
	const [pickAddress, setPickAddress] = useState('');

	const [bill, setBill] = useState([]);
	const [showBill, setShowBill] = useState(false);
	const [cashNumber, setCashNumber] = useState(undefined);

	const [startFav, setStartFav] = useState(0);

	const getStores = () => {
		fetch(`${BASE_URL}/store`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
		.then(res => res.json())
		.then(res => {
			setStores(res.filter(x => x.active).map(x => ({label: x.name + ', ' + x.address, value: x }) ));
		});
	}

	useEffect(() => {
		const params = new URLSearchParams(props.location.search);
		getStores();
	}, [])

	useEffect(() => {
		const params = new URLSearchParams(props.location.search);
		if (params.get('maincheck')) setShowSuccess(true);
	}, [window.location])

	useEffect(() => {
		setErrors({ ...errors, phone: undefined});
	}, [ phone ])
	useEffect(() => {
		setErrors({ ...errors, dt: undefined});
	}, [ dt ])
	useEffect(() => {
		setErrors({ ...errors, tm: undefined});
	}, [ tm ])
	useEffect(() => {
		setErrors({ ...errors, fio: undefined});
	}, [ fio ])
	useEffect(() => {
		setErrors({ ...errors, store: undefined});
	}, [ props.store ])
	useEffect(() => {
		setErrors({ ...errors, oferta: undefined});
	}, [ oferta ])

	const inp = (label, data, setData, error, add = null, cl = '', showError = false) => {
		const showAdd = (add !== null && !data)
		return (
			<div className={`input-container ${cl}`}>
				<div className="formel-label-center">{label}</div>
				<input value={data} onChange={(x) => setData && setData(x.target.value)} className={(error ? 'input-wrong ' : '')}/>
				{showError && 
					<div className="wrong-input">{error || ''}</div>
				}
				{showAdd &&
					add
				}
			</div>
		)
	}

	const btn = (label, click, disabled = false) => {
		return (
			<div className="check-btn" disabled={disabled} onClick={() => click()}>
				{label}
			</div>
		)
	}

	const validate = () => {
		let err = {};
		if ((phone || '').trim().replace('+', '').replaceAll(' ', '').length < 12) err['phone'] = 'ВВЕДІТЬ НОМЕР ТЕЛЕФОНУ';
		if (fio.length < 3) err['fio'] = 'Укажіть Ім’я та прізвище';
		if (!tm) err['tm'] = 'Укажіть час';
		const currTime = new Date().getTime();
		if (dt <currTime && (tm - currTime < 30 * 60 * 1000)) err['tm'] = 'Не раніше ніж через 30 хвилин';
		if (!dt) err['dt'] = 'Укажіть дату';
		if (!props.store) err['store'] = 'Укажіть магазин';
		if (!oferta) err['oferta'] = 'Погодьтесь із договором';
		// if (delmeth === DELIVERY && !pickCity) err['city'] = 'ВВЕДІТЬ СВОЮ АДРЕСУ';
		if (delmeth === DELIVERY && !pickAddress) err['address'] = 'ВВЕДІТЬ СВОЮ АДРЕСУ';
		if (delmeth === DELIVERY && calcTotal() < THRESHOLD) err['delivery'] = '*Мінімальна сума замовлення товарів ' + (THRESHOLD) + ' грн';

		setErrors(err);

		return Object.keys(err).length === 0;
	}

	
	const handleConfirm = (paymentType) => {
		const ob = { store: props.store.id, paymentType: paymeth || 'cash', phone: phone, fio: fio, card: card, comment: comment, dt: dt, tm: tm, packs: packs, connect: contact, replace: artchange, spec: props.basket.map(x => ({ article: x.article.id, name: x.article.name, price: price(x.article), qty: x.qty })), delivery: calcDelAmount(), isDelivery: delmeth === DELIVERY, deliveryAddress: delmeth === DELIVERY ? pickAddress : '', odd: odd };
		fetch(`${BASE_URL}/order`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(ob) })
		.then(res => res.json())
		.then(res => {
			if (res.result && res.result.payment && res.result.payment.short_url) window.open(res.result.payment.short_url, '_blank');
			setShowCashBank(false);
			setBill(props.basket);
			if (res && res.result && res.result.order && res.result.order.order) setCashNumber(res && res.result && res.result.order.order);
			props.handleClear();
		});
		setShowSuccess(true); 
		setShowConfirmmation(false);	
	}

	const fillUserData = () => {
		if (phone && phone.length === 12) {
			fetch(`https://api-lafar.abmloyalty.app/v2/partner/operation/user/phone/${phone}/user-info`, { method: 'GET', headers: { 'Content-Type': 'application/json', 'Authorization' : 'Basic ' + LoyConnect } })
			.then(res => res.json())
			.then(res => {
				if (res.success) {
					setFio(`${(res.data.user_data.first_name || '').trim()} ${(res.data.user_data.last_name || '').trim()}`);
					if (res.data.cards_data.length > 0) setCard(res.data.cards_data[0].number);
				}
			});
		}
	}

	const renderContact = (text, img, cont, byPay = false, byDel = false, disabled = false) => {
		const isActive = byDel ? delmeth === cont : (byPay ? cont === paymeth : cont === contact);
		const set = byDel ? () => setDelmeth(cont) : (byPay ? () => setPaymeth(cont) : () => setContact(cont));
		return (
			<div className={'check-contact ' + (isActive ? 'check-contact-active' : '') } onClick={disabled ? undefined : set}>
				<div className={'check-contact-text ' + (disabled ? 'check-contact-text-disabled' : '')}>{text}</div>
				{img &&
					<div className={img}></div>
				}
			</div>
		);
	}

	const goPay = () => {
		const ob = { id: '1', amount: 15.25 };
		fetch(`${BASE_URL}/order/pay`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(ob) })
	}

	const renderPayInp = (caption, short) => {
		return (
			<div className={'pay-inp ' + (short ? 'pay-inp-short' : '' )}>
				<div className="pay-input-title">{caption}</div>
				<input className="pay-input" ></input>
			</div>
		);
	}

	const mintime = (d) => {
		let res = new Date().setHours(7,0,0,0);

		if (moment().startOf('day').valueOf() === moment(d).startOf('day').valueOf()) {
			const delivery = moment().add(30, 'm').toObject();
			res = new Date().setHours(delivery.hours,delivery.minutes,0,0);
		}
		return res;
	}

	const calcTotal = () => {
		return Math.round(props.basket.reduce((acc, x) => { acc = acc + x.qty * price(x.article); return acc; }, 0) * 100) / 100;
	}

	// const calcDelAmount = () => (delmeth === PICKUP || total > 500) ? 0 : 49;
	const calcDelAmount = () => (delmeth === PICKUP || total > 0) ? 0 : 49;

	const history = useHistory();

	const total = calcTotal();
	const totalBill = Math.round(bill.reduce((acc, x) => { acc = acc + x.qty * price(x.article); return acc; }, 0) * 100) / 100;
	const reg = (
		<div className="check-row-card">Немає бонусної карти ? <a href="https://my-lafar.abmloyalty.app/profile/edit'">Зареєструйся!</a> </div>
	);
	const packsList = [{ label: 'МАЛЕНЬКИЙ', name: 'small', cnt: packs.small}, { label: 'СЕРЕДНІЙ', name: 'middle',  cnt: packs.middle }, { label: 'ВЕЛИКИЙ', name: 'big', cnt: packs.big }];

	const delAmount = calcDelAmount();

	const renderBillAmount = (title, totalBill) => {
		return (
			<div className="bill-info-amount">{title}:&nbsp;
			<div className="bill-info-amount-price">
				<div className="bill-info-amount-price-r">{Math.floor(totalBill)}</div>
				<div className="bill-info-amount-price-k">{kops(totalBill)}</div>
			</div>
			&nbsp;<div className="bill-info-amount-price-curr">грн</div></div>
		);
	}


	if (showCashBank) {
		return (
			<Main>
				<Breadcrumb items={[{label: 'Головна', link: ''}, { label: 'Кошик', link: 'basket'}, { label: 'Замовлення', link: 'basket'}]} />
				<div className="pay">
					<div className="pay-data">
						<div className="pay-data-col">
							<div className="pay-data-title">
								ОПЛАТА КАРТКОЮ
							</div>
							{renderPayInp('НОМЕР КАРТКИ')}
							{renderPayInp('СРОК ДІЇ')}
							{renderPayInp('CVV2\\CVC2')}
						</div>
						<div className="pay-data-col">
							<div className="pay-data-title">
								ДАНІ ПЛАТЕЖУ
							</div>
							{renderPayInp('ID ЗАМОВЛЕННЯ')}
							{renderPayInp('ТОРГІВЕЦЬ')}
							{renderPayInp('ОПИС')}
							{renderPayInp('СУМА')}
						</div>
					</div>
					<div className="modal-row-btn" onClick={() => { handleConfirm() }}>ПІДТВЕРДЖУЮ</div>
				</div>
			</Main>
		)
	} else return props.store ? (
		<Main>
			<Breadcrumb items={[{label: 'Головна', link: ''}, { label: 'Кошик', link: 'basket'}]} />
			{(showSuccess === undefined && !showBill) &&
				<div className="basket-list">
					{props.basket.map(x => (
						<div className="basket-item">
							<div className="item-left">
								<div className="item-img" style={{ backgroundImage: `url(${BASE_URL}/images/article/${x.article.img})` }} />
								<div className="item-details">
									<div className="item-name">{x.article.name}</div>
									<div className="item-qty">
										Количество: 
										<FaMinusCircle onClick={() => props.handleMinus(x.article, isWeight(x.article) ? 0.1 : 1)} />
										&nbsp;{qtyPres(x.qty, isWeight(x.article))}
										<FaPlusCircle onClick={() => props.handlePlus(x.article, isWeight(x.article) ? 0.1 : 1)} />
									</div>
									<div className="item-price">
										<div className="article-price-1">
											{Math.floor(x.qty * price(x.article))}
										</div>
										<div className="article-price-2">
											{kops(x.qty * price(x.article))}
										</div>
									</div>
								</div>
							</div>
							<FaRegTrashAlt className="trash" onClick={() => { props.handleDel(x.article.id) }} />
						</div>
					))}
					<div className={'check ' + (props.theme === 'white' ? 'check-white' : '')}>
						<div className="check-left">
							<div className="check-title">Чек</div>
							<div className="check-title-container">
								{(delmeth === DELIVERY && delAmount > 0) &&
									<div className="check-title-2-w">Доставка: {rkop(delAmount)} грн</div>
								}
								<div className="check-title check-title-w">Загальний Рахунок: {rkop(total + delAmount)} грн</div>
							</div>
							<div className="check-title-2">АДРЕСИ ДОСТАВКИ:</div>
							<div className="check-row">
								{/* {inp('МІСТО', delmeth === PICKUP ? props.store.name : pickCity, undefined, '', null, '', true)} */}
								{inp('МІСТО', props.store.name, undefined, '', null, '', true)}
								{inp('ВУЛИЦЯ, БУДИНОК', delmeth === PICKUP ? props.store.address : pickAddress, delmeth === PICKUP ? '' : setPickAddress, delmeth === DELIVERY ? errors.address : '', null, 'input-container-m', true)}
								{/* {inp('АДРЕСА МАГАЗИНУ')} */}
							</div>

							<div className="check-row check-row-dttm">
								<div className="input-container">
									<div className="formel-label formel-label-center">ДАТА</div>
									<DatePicker locale="el" selected={dt} onChange={date => setDt(date)} className={'date-input ' + (errors.dt ? 'input-wrong' : '')}  dateFormat="dd.MM.yyyy" minDate={new Date().getTime()} maxDate={new Date().getTime() + 2*24*3600*1000} />
									<div className="wrong-input">{''}</div>
								</div>
								<div className="dt-container">
									<div className="formel-label formel-label-center">ЧАС</div>
									<DatePicker locale="el" selected={tm} onChange={time => setTm(time)} className={'date-input ' + (errors.tm ? 'input-wrong' : '')} showTimeSelect showTimeSelectOnly timeIntervals={30} timeCaption="Час" dateFormat="HH:mm" minTime={mintime(dt)} maxTime={new Date().setHours(19,30,0,0)} />
									<div className="wrong-input">{errors.tm || ''}</div>
								</div>
							</div>
							<div className="check-title">Контактні дані</div>
							<div className="check-row">
								<div className="phone-container">
									<div className="formel-label">НОМЕР ТЕЛЕФОНУ</div>
									<PhoneInput country={'ua'} value={phone} inputClass={(errors.phone ? 'input-wrong' : '')} onChange={phone => setPhone(phone)} onBlur={() => fillUserData()} />
									<div className="wrong-input">{errors.phone || ''}</div>
								</div>
								{/* {inp('НОМЕР ТЕЛЕФОНУ')} */}
								{inp('ІМ’Я ТА ПРІЗВИЩЕ', fio, setFio, errors.fio, undefined, 'input-margin', true)}
							</div>
							<div className="check-row">
								{inp('НОМЕР БОНУСНОЇ КАРТИ', card, setCard, errors.card, reg)}
								{/* <div className="shop-container input-margin">
									<div className="formel-label">АДРЕСА МАГАЗИНУ</div>
									<Select options={stores} className={(errors.store ? 'input-wrong' : '') + ' formel formel-select'} value={props.store ? stores.find(x => x.value.id === props.store.id) : undefined} onChange={(v) => props.storeChoose(v.value)} />
								</div> */}
								{/* {inp('АДРЕСА МАГАЗИНУ')} */}
							</div>
							<div className="check-title">ЯК З ВАМИ ЗВ’ЯЗАТИСЯ?</div>
							<div className="check-contacts">
								{renderContact('ПОВІДОМЛЕНЯ У TELEGRAM', 'check-telegram', 'telegram')}
								{renderContact('ПОВІДОМЛЕНЯ У VIBER', 'check-viber', 'viber')}
								{renderContact('ЗАТЕЛЕФОНУВАТИ', 'check-phone', 'phone')}
							</div>
							<div className="check-mark">
								{/* <input type="checkbox" value={artchange} onChange={(e) => setartchange(e.target.value)} /> */}

								<div className="check-mark-box" onClick={() => setartchange(!artchange)}>
									{artchange &&
										<div>L</div>
									}
								</div>
								ЗРОБИТИ ЗАМІНУ ТОВАРА НА НАШ ПОГЛЯД (У РАЗІ ЙОГО ВІДСУТНОСТІ, ТОЩО)
							</div>
						</div>
						<div className="check-right">
							<div className="check-right-meth">
								<div className="check-right-meth-del">
									<div className="check-right-label-big">ДОСТАВКА</div>
									<div className="check-contacts check-contacts-col">
										{renderContact('САМОВИВІЗ', undefined, PICKUP, false, true)}
										{renderContact('ДОСТАВКА', undefined, DELIVERY, false, true)}
									</div>
								</div>
								<div className="check-right-meth-pay">
									<div className="check-right-label-big">ОПЛАТА</div>
									<div className="check-contacts check-contacts-col">
										{/* {renderContact('БЕЗГОТІВКОВИЙ РОЗРАХУНОК', undefined, 'cashless', true)} */}
										{/* {renderContact('КАРТКОЮ ПРИ ОТРИМАННІ', undefined, 'card', true)} */}
										{renderContact('КАРТКОЮ НА САЙТІ', undefined, 'cardsite', true)}
										{renderContact('ГОТІВКОЮ ПРИ ОТРИМАННІ', undefined, 'cash', true)}
									</div>
								</div>
							</div>
							<div className="wrong-input">{errors.delivery || ''}</div>
							<div className="wrong-input"></div>

							<div className={`check-right-reshta ` + (delmeth === DELIVERY && paymeth === 'cash' ? 'check-right-reshta-hi' : 'check-right-reshta-low')}>
								<div className="check-right-label">З ЯКОЇ СУМИ ВАМ ПОТРІБНА РЕШТА?</div>
								<input className="check-odd" value={odd} onChange={(e) => setOdd(e.target.value)} />
							</div>
							<div className="check-right-label">КОМЕНТАР ДО ЗАМОВЛЕННЯ</div>
							<textarea rows={mobile ? 4 : 5} value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
							<div className="pack-container">
								<div className="pack-header" onClick={() => setPackOpen(!packOpen)}>
									{packOpen ? (<FaChevronDown />) : (<FaChevronRight />)}
									<div className="pack-label">ПАКЕТ</div>
								</div>
								{packOpen &&
									<div className="pack-packs">
										{packsList.map(x => (
											<div className="pack-pack">
												<div>
													<input type="checkbox" name={x.name} id={x.name} checked={packs[x.name] > 0} onClick={() => { if (packs[x.name] === 0) setPacks({...packs, [x.name]: packs[x.name] + 1}); else setPacks({...packs, [x.name]: 0}); } } />
													<label for={x.name}>{x.label}</label>
												</div>
												<div className="pack-pack-cnt">
													<FaMinusCircle onClick={() => setPacks({...packs, [x.name]: Math.max(0, packs[x.name] - 1)})} />
													<div>{x.cnt}</div>
													<FaPlusCircle onClick={() => setPacks({...packs, [x.name]: packs[x.name] + 1})} />
												</div>
											</div>
										))}
									</div>
								}
							</div>
							<div className="btns">
								<div className="check-mark">
									<div className="check-mark-box" onClick={() => setOferta(!oferta)}>
										{oferta &&
											<div>L</div>
										}
									</div>
									<div>
										Згода з&nbsp;<Link to="/conditions">договором публічної оферти</Link>
									</div>
								</div>
								{errors.oferta &&
									<div className="wrong-input">{errors.oferta}</div>
								}
								{btn('ДОДАТИ ТОВАР', () => history.push('/store'))}
								{btn('ЗАМОВИТИ', () => { if (validate()) { if (paymeth === 'cardsite') handleConfirm(); else setShowConfirmmation(true); } })}
								{/* {btn('ЗАМОВИТИ', () => { if (validate()) setShowConfirmmation(true);})} */}
								{/* {btn('ЗАМОВИТИ', () => { if (validate()) goPay();})} */}
							</div>
						</div>
					</div>
					{(props.fav && props.fav.length > 0) &&
						<div className="favorites-container">
							<div className="favorites"></div>
							<ArtCarousel 
								items={carItems(props.fav, startFav, 4).map((x) => ({ ...x }) )}
								setStart={(ch) => setStartFav(props.fav.length === 0 ? 0 : ((startFav + ch < 0) ? props.fav.length - 1 : ((startFav + ch) % props.fav.length))) }
							/>
						</div>
					}
				</div>
			}
			{showSuccess &&
				<div className="order-success">
					<div className="order-success-row">
						ДЯКУЄМО ЗА ЗАМОВЛЕННЯ! 
					</div>
					<div className="order-success-row">
						ЧЕКАЄМО НА ВАС В МАГАЗИНІ 
					</div>
					<div className="order-success-row">
						<div className="order-success-row-btn" onClick={() => { history.push('/'); setShowSuccess(undefined);} }>
							НА ГОЛОВНУ
						</div>
					</div>
					<div className="order-success-row">
						<div className="order-success-row-bill" onClick={() => { setShowBill(true); setShowSuccess(undefined);} }>
							Чек
						</div>
					</div>
				</div>
			}
			{showConfirmmation &&
				<div id="myModal" class="modal">
					<div class="modal-content">
						<span class="close" onClick={() => setShowConfirmmation(false)}>&times;</span>
						<div className="order-confirm-row">
							ПІДТВЕРДІТЬ ЗАМОВЛЕННЯ, БУДЬ ЛАСКА.
						</div>
						<div className="modal-row order-confirm-row-buttons">
							<div className="modal-row-btn" onClick={() => { handleConfirm() }}>ПІДТВЕРДЖУЮ</div>
							<div className="modal-row-btn" onClick={() => setShowConfirmmation(false)}>ДО МАГАЗИНУ</div>
						</div>
					</div>
				</div>
			}
			{showCashBank &&
				<div id="myModal" class="modal">
					<div class="modal-content">

						{/* <span class="close" onClick={() => setShowConfirmmation(false)}>&times;</span>
						<div className="order-confirm-row">
							ЗАСІБ ОПЛАТИ
						</div>
						<div className="modal-row order-confirm-row-buttons">
							<div className="modal-row-btn" onClick={() => handleConfirm('cash')}>ГОТІВКОЮ</div>
							<div className="modal-row-btn" onClick={() => handleConfirm('card')}>КАРТОЮ</div>
						</div> */}
					</div>
				</div>
			}
			{showBill &&
				<div className="bill">
					<div className="bill-container">
						<div className="bill-info">
							<div className="bill-info-title">Чек {cashNumber ? '№ ' + cashNumber : ''}</div>
							<div className="bill-info-address">{delmeth === DELIVERY ? 'Доставка' : 'Самовивіз'}</div>
							<div className="bill-info-address">Адреси: {delmeth === DELIVERY ? props.store.name + ' ' +  pickAddress : (props.store && props.store.address)}</div>
							{renderBillAmount('СУМА ТОВАРІВ', totalBill)}
							{/* {renderBillAmount('СУМА ДОСТАВКИ', delAmount)} */}
							{renderBillAmount('ДО СПЛАТИ', totalBill + delAmount)}
							{/* <div className="bill-info-amount">Загальний Рахунок:&nbsp;
							<div className="bill-info-amount-price">
								<div className="bill-info-amount-price-r">{Math.floor(totalBill)}</div>
								<div className="bill-info-amount-price-k">{kops(totalBill)}</div>
							</div>
							&nbsp;грн</div> */}
							<div className="bill-info-pick">Замовлення {delmeth === DELIVERY ? 'доставлять' : 'зберуть'}</div>
							<div className="bill-info-pick-date">{moment(dt).format('DD.MM.YYYY')}    {moment(tm).format('HH:mm')}</div>
							<div className="bill-info-contact">Контактні Дані</div>
							<div className="bill-info-label">Ім'я та прізвище</div>
							<div className="bill-info-value">{fio}</div>
							<div className="bill-info-label">Номер телефону</div>
							<div className="bill-info-value">{phonepres(phone)}</div>
						</div>
						<div className="bill-items">
							{bill.map(x => (
								<div className="bill-item" key={x.article.id}>
									<div className="bill-item-left">
										<div>{x.article.name}</div>
										<div>{qtyPres(x.qty, isWeight(x))} {x.article.unit}</div>
									</div>
									<div className="bill-item-right">
										<div className="bill-item-right-r">{Math.floor(x.qty * price(x.article))}</div>
										<div className="bill-item-right-k">{kops(x.qty * price(x.article))}</div>
									</div>
								</div>
							))}
						</div>
					</div>
					<div className="bill-footer">{paymeth === 'cardsite' ? 'оплачено' : 'не оплачено'}</div>
				</div>
			}
		</Main>
	) : (
		<Main>
			<Breadcrumb items={[{label: 'Головна', link: ''}, { label: 'Кошик', link: 'basket'}]} />
			<ChooseStore />
			{/* <Construction /> */}
		</Main>
	);
};

const mapStateToProps = (state) => ({
	basket: state.basket,
	store: state.store,
	fav: state.fav,
	theme: state.theme
});
const mapDispatchToProps = (dispatch) => ({
	handleDel: (id) => dispatch(basketDel(id)),
	handleMinus: (article, qty) => dispatch(basketAdd({ article: article, qty: -qty })),
	handlePlus: (article, qty) => dispatch(basketAdd({ article: article, qty: qty })),
	handleClear: () => dispatch(basketClear()),
	storeChoose: (store) => dispatch(choose(store))
});


export default connect(mapStateToProps, mapDispatchToProps)(Basket);
