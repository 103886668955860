import React from 'react';
import { Link } from 'react-router-dom';
import './menu.scss';
import { ReactComponent as MobileLogo } from '../assets/images/mobilelogo.svg';
import { FB_URL, getMobileLink } from '../config/config';

const OuterLink = (props) => {

	return (
		<a target="_blank" href={props.href} rel="noopener noreferrer">
			{props.children}
		</a>
	)
}

const Menu = (props) => {

	const items = [{label: 'ЧОМУ МИ?', path: '/we'}, {label: 'ВАКАНСІЇ', path: '/vacancy'}, {label: 'БОНУСНА ПРОГРАМА', path: '/bonuses'},
		{label: 'ONLINE МАГАЗИН', path: '/store'}, {label: 'АКЦІЇ', path: '/promotions'}, {label: 'АДРЕСИ МАГАЗИНІВ', path: '/address'}, 
        // {label: 'ОСОБИСТИЙ КАБІНЕТ', path: 'https://my-lafar.abmloyalty.app/profile/edit'}
    ];
	const mobileItems = [{label: 'АКЦІЇ', path: '/promotions'}, {label: 'ВАКАНСІЇ', path: '/vacancy'}, {label: 'БОНУСНА ПРОГРАМА', path: '/bonuses'},
		{label: 'ONLINE МАГАЗИН', path: '/store'}, {label: 'АДРЕСИ МАГАЗИНІВ', path: '/address'}, 
        // {label: 'ОСОБИСТИЙ КАБІНЕТ', path: 'https://my-lafar.abmloyalty.app/profile/edit'}, 
        {label: 'ЧОМУ МИ?', path: '/we'},
		// {label: 'МОБІЛЬНИЙ ДОДАТОК', path: getMobileLink()}
    ];


	const renderItem = (item) => {

		const Comp = (item.path.startsWith('http://') || item.path.startsWith('https://')) ? OuterLink : Link;
		return (
			<Comp to={item.path} key={item.label} href={item.path}>
				<div className="menu-item">
					{item.label}
				</div>
			</Comp>
		);
	}

	const renderMobileItem = (item) => {

		const Comp = (item.path.startsWith('http://') || item.path.startsWith('https://')) ? OuterLink : Link;
		return (
			<Comp to={item.path} key={item.label} href={item.path}>
				<div className="mobile-menu-item">
					{item.label}
				</div>
			</Comp>
		);
	}

	const renderLine = () => {
		return (
			<div className="mobile-menu-line">
				<svg width="101" height="1" viewBox="0 0 101 1" fill="none" xmlns="http://www.w3.org/2000/svg">
					<line y1="0.5" x2="101" y2="0.5" stroke="black" stroke-opacity="0.4"/>
				</svg>
			</div>
		)
	}

	return (
		<>
			<div className="menu">
				{items.map(i => renderItem(i))}
			</div>
			<div className={'menu-mobile ' + (props.showMenu ? 'menu-mobile-show' : '')} onClick={() => props.hideMenu()}>
				<Link to="/">
					<MobileLogo />
				</Link>
				{renderLine()}
				{mobileItems.map(i => (
					<>
						{renderMobileItem(i)}
						{renderLine()}
					</>
				))}
				<div className="menu-social">
					<a href="https://www.instagram.com/lafar.ua/?hl=uk" target="blank"><div className="menu-inst"></div></a>
					<a href={FB_URL} target="blank"><div className="menu-fb"></div></a>
				</div>
			</div>
		</>
	);
}

export default Menu;