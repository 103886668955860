import React, { useState, useEffect } from "react";
import Main from "./main";
import Breadcrumb from "../components/breadcrumb";
import Construction from '../components/construction';
import './promotions.scss';
import { BASE_URL } from '../config/config';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';


const Promotions = () => {

	const history = useHistory();
	useEffect(() => {
		fetch(`${BASE_URL}/promotion/show`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => res.json())
			.then(res => {
				setMedia(res || []);
				setBack(true);
			})
	}, [])

	const list = [
		{id: 5, name: 'promotion6', store: false, desc: '' },
		{id: 1, name: 'promotion1', store: false, desc: 'Вартість кожної (найменшої за вартістю) п\'ятої піци в одному чеку - 1 коп. Акція діє на постійній основі в кафетерії LACAFE.' },
		{id: 2, name: 'promotion2', store: false, desc: 'На кожен чек на суму від 300 грн. нараховується знижка в розмірі 10% від усієї вартості. Акція діє на постійній основі і тільки в кафетерії LACAFE.' },
		{id: 3, name: 'promotion3', store: false, desc: 'До кожного La Beff бургеру - стакан лимонаду в подарунок (за 1 коп.). Акція діє на постійній основі і тільки в кафетерії LACAFE.' },
		{id: 4, name: 'promotion4', store: true, desc: `Роби покупки 8 серпня у LAFAR маркеті на суму від 200 грн. (одним чеком), та отримай можливість виграти один з двадцяти крутих призів. Тягни лотерейку у нашому барабані та обирай свій подарунок! Акція діє лише 8 серпня  з 10:00 до 15:00 за адресою: м Костянтинівка, вул, Інтернаціональна, 464.  Вигравай: •Велосипед Wolverine •Гойдалка садова •Стіл розкладний зі стільцями •Тример садовий Bosch •Драбина-стрем’янка •Вентилятор Wild Wind •Автохолодильник 22 л •Пилосос Rowenta •Праска Tefal •Мангал Похідний •Продуктові набори від LAFAR - 10 шт.` }
	]

	const [media, setMedia] = useState([]);
	const [type, setType] = useState('cafe');
	const [text, showText] = useState();
	const [back, setBack] = useState(false);


	return (
		<Main>
			<Breadcrumb items={[{label: 'Головна', link: ''}, { label: 'Акції', link: 'promotions'}].concat(type === 'promotion' ? [{ label: 'Всі товари', link: 'promotions' }] : [] )} />
			{type !== 'promotion' &&
				<div className="promotions-type">
					<div className={'promotion-type' + (type === 'cafe' ? ' chosen' : '')} onClick={() => setType('cafe')}><div>КАФЕТЕРІЙ</div></div>
					<div className={'promotion-type' + (type === 'store' ? ' chosen' : '')} onClick={() => setType('store')}><div>МАГАЗИН</div></div>
					<div className={'promotion-type' + (type === 'promotion' ? ' chosen' : '')} onClick={() => history.push('/store?promo=1')}><div>БІЛЬШЕ АКЦІЙ</div></div>
				</div>
			}
			{type !== 'promotion' &&
				<div className="promotions">
					{media.filter(x => x.store === (type === 'store')).map(x => (
						<div style={text === x.id || !back ? {} : { backgroundImage: `url(${BASE_URL}/images/${x.path})`}} className={'promotion ' + (text === x.id ? 'promotion-text' : x.name)} key={x.id} onMouseEnter={() => showText(x.id)} onMouseLeave={() => showText(undefined)} onClick={() => text === x.id ? showText(undefined) : showText(x.id)}>
							{text === x.id &&
								<div className="promotion-text-content">{x.desc}</div>
							}
						</div>
					))}
				</div>
			}
		</Main>
	);
};

export default Promotions;
