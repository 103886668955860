import React from "react";
import Main from "./main";
import Breadcrumb from "../components/breadcrumb";
import Construction from '../components/construction';
import './bonuses.scss';
import { connect } from 'react-redux';

const Bonuses = ({ theme }) => {
	return (
		<Main>
			<Breadcrumb items={[{label: 'Головна', link: ''}, { label: 'Бонусна програма', link: 'bonuses'}]} />
			<div className="bonus-start-container">
				<div className="bonus-start">
					{/* <div className="bonus-gear">
						<div className="bonus-gear-text">З 19 Жовтня</div>
					</div> */}
					{/* <div className="bonus-start-text">Стартує універсальна бонусна програма <span className="bonus-start-text-zebra">Zebra</span></div> */}
					<div className="bonus-start-text">універсальна бонусна програма <span className="bonus-start-text-zebra">Zebra</span></div>
				</div>
			</div>
			<div className="bonus-adv">
				<div className="bonus-adv-title">
					Переваги бонусної програми <span className="bonus-adv-zebra">Zebra</span>
				</div>
				<div className="bonus-adv-zebra-list">
					<div className="bonus-zebra"></div>
					<div className="bonus-adv-list">
						<div className="bonus-adv-text">
							<span className="bonus-adv-text-c">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
							Нарахування решти на бонусну картку!
						</div>
						<div className="bonus-adv-text">
							<span className="bonus-adv-text-c">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
							Бонуси з кожної покупки!
						</div>
						<div className="bonus-adv-text">
							<span className="bonus-adv-text-c">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
							Оплата покупки бонусами!
						</div>
					</div>
				</div>
			</div>
			<div className="bonus-how">
				<div className={'bonus-wave ' + (theme === 'black' ? 'bonus-wave-b' : 'bonus-wave-w')}>
					<div className="bonus-wave-margin"></div>
					<div className="bonus-how-title">ЯК зараховуються бонуси?</div>
					<div className="bonus-how-desc">З кожної покупки Учасник Програми отримує знижку 1% від суми покупки у вигляді нарахувань бонусів на картку</div>
					<div className="bonus-how-box">
						<div>1 БОНУС = 1 КОПІЙКА</div>
						<div>100 БОНУСІВ = 1 ГРИВНЯ</div>
					</div>
					<div className="bonus-how-txt1">
						За кожну 1 гривню, сплачену у мережі супермаркетів та кафетеріїв «LAFAR» Учасник Програми  отримує на Рахунок 1 (один) Бонус. 1 Бонус дорівнює 1 копійці, 100 бонусів дорівнює 1 гривні.
					</div>
					<div className="bonus-how-txt2">
						Учасник може списати Бонуси у розмірі до 90% всієї суми чека.
					</div>
					{/* <div className={'bonus-bday ' + (theme === 'black' ? 'bonus-bday-b' : 'bonus-bday-w')}>
					</div> */}
                    {/* <div className="bonus-wave-aug"></div> */}
				</div>
				{/* <div className={'bonus-bwave ' + (theme === 'black' ? 'bonus-bwave-b' : 'bonus-bwave-w')}></div> */}
			</div>
			{/* <div className="bonus-mob">
				<div className="bonus-mob-1">МОЖЛИВОСТІ, ЯКІ ЗАВЖДИ З ВАМИ!</div>
				<div className="bonus-mob-2">Віртуальна картка у вашому смартфоні!</div>
				<div className="bonus-mob-3">
					Долучайтеся до програми лояльності!
					<br />
					Реєструйтеся за допомогою віртуальних сервісів:
				</div>
				<div className={'bonus-mob-img ' + (theme === 'black' ? 'bonus-mob-img-b' : 'bonus-mob-img-w')}></div>
			</div> */}
			<div className="bonus-card">
				{/* <div className="bonus-header">
					<div className={'bonus-card-img ' + (theme === 'black' ? 'bonus-card-img-b' : 'bonus-card-img-w')}>
						Встановлюйте мобільний додаток або чат бот і будь в курсі останніх новин. 
						<br />
						Не потрібно носити картку з собою, вона завжди у вашому телефоні.
						<br />
						Нарахування бонусів можливо за номером телефону. 
						<br />
						<strong>Скануйте віртуальну картку з екрану мобільного пристрою на касі.</strong>
						<br />
						<br />
					</div>
				</div> */}
				<div className="bonus-bg">
					{/* <div className="bonus-txt">Віртуальна картка</div>
					<div className="bonus-bg-bar-cont">
						<div className="bonus-bg-bar"></div>
					</div> */}
					{/* <div className="bonus-txt">у вашому смартфоні</div>
					<div className="bonus-txt bonus-txt-green">за один клІк!</div>
					<div className={'bonus-phone ' + (theme === 'black' ? 'bonus-phone-b' : 'bonus-phone-w')}></div> */}
                    <div className="bonus-bg-how-get">Як отримати бонусну картку</div>
					<div className="bonus-card-zebra"></div>
					<div className="bonus-desc">
						Зробіть покупку на суму від 100грн та отримайте картку разом з 
						<br />
						анкетою бонусної програми на касі у будь-якому <strong>Lafar</strong>-маркеті. 
						<br />
						Заповніть анкету, впишіть номер картки у відповідне поле та 
						<br />
						залиште анкету у касира.
						<br />
						<strong>Користуватися карткою можна вже зараз.</strong>
					</div>
					<div className="bonus-part">УЧАСТЬ У ПРОГРАМІ</div>
					<div className="bonus-square">
						<ul>
							{/* <li>Зробити покупку на суму від 100грн.</li>
							<br /> */}
							<li>До участі в Програмі допускаються особи, які досягли 16 років.</li>
							<br />
							<li>Обов'язковою умовою участі у Програмі є авторизація та заповнення анкети.</li>
							<br />
							<li>Картка може бути придбана за 10 коп. у мережі супермаркетів та кафетеріях LAFAR.</li>
							<br />
							<li>На один номер мобільного телефону може бути зареєстрована лише одна Картка.</li>
							<br />
							<li>Якщо протягом 3 місяців поспіль відсутні операції з використанням Картки, накопичені Бонуси списуються з Рахунку.</li>
						</ul>
					</div>
					<div className="bonus-desc bonus-desc-width">
						<strong>
                            Бонуси НЕ нараховуються і НЕ списуються при купівлі тютюнових
                            <br />
                            виробів, алкогольних напоїв та акційних товарів.
						</strong>
					</div>
				</div>
				<div className="bonus-rules">
					<div className="bonus-rules-title">ОФІЦІЙНІ ПРАВИЛА БОНУСНОЇ ПРОГРАМИ</div>
					<a href={`${window.location.origin}/rules.pdf`} target="_blank" rel="noopener noreferrer"><div className={'bonus-btn ' + (theme === 'black' ? 'bonus-btn-b' : 'bonus-btn-w')}></div></a>
					{/* <a href={`https://my-lafar.abmloyalty.app/profile/edit`} target="_blank" rel="noopener noreferrer"><div className="bonus-reg">РЕЄСТРАЦІЯ</div></a> */}
					<a href={`/questions`}><div className="bonus-reg">Питання</div></a>
				</div>
				{/* <div className={'bonus-ellipse ' + (theme === 'black' ? 'bonus-ellipse-b' : 'bonus-ellipse-w')}>
					<div className="bonus-ellipse-text">
						ЯК ОТРИМАТИ ПЛАСТИКОВУ 
						<br />
						БОНУСНУ КАРТКУ?
					</div>
				</div> */}
			</div>
		</Main>
	);
};

const mapStateToProps = (state) => ({
	theme: state.theme
});

export default connect(mapStateToProps)(Bonuses);
